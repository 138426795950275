import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCsatOptions } from '../../services/csatService'

const initialState = {
  csatoptionsdata: {},
  isLoadingcsatoptions: false,
  errorcsatoptions: null,
}

export const fetchCsatOptions = createAsyncThunk(
  'content/fetchCsatOptions',
  async () => {
    const response = await getCsatOptions()
    if (response.success) {
      return response.data.result
    }

    return {}
  }
)

export const csatSlice = createSlice({
  name: 'csat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCsatOptions.pending, (state) => {
      state.isLoadingcsatoptions = true
    })
    builder.addCase(fetchCsatOptions.fulfilled, (state, action) => {
      state.isLoadingcsatoptions = false
      state.csatoptionsdata = action.payload
    })
    builder.addCase(fetchCsatOptions.rejected, (state, action) => {
      state.isLoadingcsatoptions = false
      state.errorcsatoptions = action.error.message
    })
  },
})

export default csatSlice.reducer
