import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { CSVLink } from 'react-csv'
import Papa from 'papaparse'
import ExcelJS from 'exceljs'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../Common/Loading'
// import { fetchPhaseDetails } from '../../../../redux/reducer/complianceSlice'
import { useSnackbar } from '../../../Common/Libraries/Notify'
import * as XLSX from 'xlsx'
import ExcelGenerator from './ex'
import { getAllMetaData } from '../../../services/csatService'
import API from '../../../Common/api'
import { fetchCsatOptions } from '../../../redux/reducer/csatSlice'
import _ from 'lodash'

const CsatBulkUpload = (props) => {
  const [isDisabled, setisDisabled] = useState(true)
  const [isValidData, setIsValidData] = useState(false)
  const [idsAddedData, setIdsAddedData] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  const { enqueueSnackbar: notify } = useSnackbar()
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const { data: adminData } = useSelector((state) => state.admin)
  const { userDetails } = useSelector((state) => state.user)
  const { csatoptionsdata } = useSelector((state) => state.csat)
  const [uploadTemplateData, setUploadTemplateData] = useState([])
  const uniqueEmployees = adminData.employeeDetails.filter(
    (item, index, self) =>
      index === self.findIndex((obj) => obj.email_id === item.email_id)
  )
  const [revenuesOptions, setRevenuesOptions] = useState([])
  const [designationOptions, setDesignationOptions] = useState([])
  const [reqDta, setReqDta] = useState([])
  const [recordsToUpdate, setRecordsToUpdate] = useState([])
  const [countriesOptions, setCountriesOptions] = useState([])
  const tenures = ['<3 months', '3-6 months', '6-12 months', '>12 months']
  const fileInputRef = useRef(null);
  const columnNames = [
    'Row Id',
    'Vertical*',
    'Account*',
    'Project*',
    'Work Stream',
    'Project Lead Email',
    'CS Email*',
    'Internal Surveyee*',
    'Salutation*',
    'Client First Name*',
    'Client Last Name*',
    'Client Email*',
    'Client Designation*',
    'Client Preferred Name*',
    'Client Tenure*',
    'Client Base Location (Country)*',
    'Client LinkedIn',
    'Revenue Bucket*',
    'Survey Type*',
    'Transformational Engaged*',
    'Mode Of Survey*',
    'Client Level*',
    'Service Line*',
    ...(userDetails.role === 'Admin' 
        ? ['Alternate Client Name', 'Alternate Client Email'] 
        : []),
    'Unique Id*'
];

  
  const [deletedHeaderValues, setDeletedHeaderValues] = useState([])
  const csatData=props.nonExcludedrows
  const handleInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setisDisabled(true)
    }
  };
  const employeeEmails = uniqueEmployees.map((item) => {
    return item.email_id
  })

  const handleFileUpload = async (idsAddedData) => {
    if (isValidData && reqDta.length>0) {
      setOpenBackdrop(true)
      await API.post(`csat/saveBulkCsatData`, {
        reqDta
       })
        .then((response) => {
          setOpenBackdrop(false)
          notify('Data uploaded successfully', {
            variant: 'success',
          })
          setReqDta([])
          setisDisabled(true)
          props.setOpenBulkUploadModel(false)
          props?.getCsat()
        })
        .catch(() => {
          notify('error in saving bulk reviews!', {
            variant: 'error',
          })
          setisDisabled(true)
          setOpenBackdrop(false)
        })
    }
  }

  useEffect(() => {
    const fetchRevenuesList = async () => {
      try {
        const response = await API.get(`/csat/getRevenuesList`)
        const data = response.data.result.map((item) => {
          return item.revenues
        })
        setRevenuesOptions(data)
      } catch (error) {
      }
    }
    const fetchCountriesList = async () => {
      try {
        const response = await API.get(`/csat/getCountriesList`)
        setCountriesOptions(response.data.result)
      } catch (error) {
      }
    }
    fetchRevenuesList()
    fetchCountriesList()
    dispatch(fetchCsatOptions())
  }, [])
  const closeBulkUploadModel = () => {
    props.setOpenBulkUploadModel(false)
    setisDisabled(true)
  }

  const getMetaData = () => {
    setOpenBackdrop(true)
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    getAllMetaData(data)
      .then((res) => {
        if (res.success) {
          setUploadTemplateData(res.data.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }

  useEffect(() => {
    getMetaData()
  }, [])
  const closeAlert = () => {
    setOpenAlert(false)
  }

  const setInvalidMessageContent = (invaliData, keyName) => {
    return invaliData.map((v) => {
      if (keyName === 'metaData') {
        const { vertical, account, project, workstream } = v.metaData;
        const { vertical: itemVertical, account: itemAccount, project: itemProject, workStream: itemWorkStream } = v.item;
        let message = '';
        let actualValues = '';
    
        if (itemVertical !== vertical) {
            message += `Vertical: ${itemVertical}`;
            actualValues += `Vertical: ${vertical}`;
        }
    
        if (itemAccount !== account) {
            if (message) {
                message += ', ';
                actualValues += ', ';
            }
            message += `Account: ${itemAccount}`;
            actualValues += `Account: ${account}`;
        }
    
        if (itemProject !== project) {
            if (message) {
                message += ', ';
                actualValues += ', ';
            }
            message += `Project: ${itemProject}`;
            actualValues += `Project: ${project}`;
        }
    
        if (itemWorkStream && workstream && itemWorkStream !== workstream) {
            if (message) {
                message += ', ';
                actualValues += ', ';
            }
            message += `Workstream: ${itemWorkStream}`;
            actualValues += `Workstream: ${workstream}`;
        }
    
        if (message && actualValues) {
            message = `Invalid Data - ${message}`;
            actualValues = `Please find correct Data - ${actualValues}`;
        }    
        return (
            <><div style={{fontWeight:'bold'}}>Row:{v.rowNumber}</div>
                <div style={{marginLeft:'8px'}}>{message}</div>
                <p style={{marginLeft:'8px'}}>{actualValues}</p>
            </>
        );
    }
      if (keyName === 'invalidClientEmail') {
        return (
          <p>
            Row {v.rowNumber}-{v.clientEmail}
          </p>
        )
      }
      if (keyName === 'invalidCsEmail') {
        return (
          <p>
            Row {v.rowNumber}-{v.csEmail}
          </p>
        )
      }
      if (keyName === 'inValidInternalSurveyEmail') {
        return (
          <p>
            Row {v.rowNumber}-{v.isEmail}
          </p>
        )
      }
      if (keyName === 'invalidCs') {
        return (
          <p>
            Row {v.rowNumber}-{v.invalidCs}
          </p>
        )
      }
      if (keyName === 'inValidInternalSurvey') {
        return (
          <p>
            Row {v.rowNumber}-{v.internalSurveyee}
          </p>
        )
      }
      if (keyName === 'invalidRevenues') {
        return (
          <p>
            Row {v.rowNumber}-{v.revenue}
          </p>
        )
      }
      if (keyName === 'invalidTenures') {
        return (
          <p>
            Row {v.rowNumber}-{v.tenure}
          </p>
        )
      }
      if (keyName === 'inValidDirectClient') {
        return (
          <p>
            Row {v.rowNumber}-{v.directClient}
          </p>
        )
      }
      if (keyName === 'invalidCountries') {
        return (
          <p>
            Row {v.rowNumber}-{v.clientLocation}
          </p>
        )
      }
      if (keyName === 'invalidGenericTypes') {
        return (
          <p>
            Row {v.rowNumber}-{v.invalidSurveyType}
          </p>
        )
      }
      if (keyName === 'invalidDesignations') {
        return (
          <p>
            Row {v.rowNumber}-{v.designation}
          </p>
        )
      }
      if (keyName === 'inValidProjectLeads') {
        return (
          <p>
            Row {v.rowNumber}-{v.projectLead}
          </p>
        )
      }
      if (keyName === 'existedClientName') {
        return (
          <p>
            Row {v.rowNumber}-{v.duplicateClientName}
          </p>
        )
      }
      if (keyName === 'inValidLeadEmail') {
        return (
          <p>
            Row {v.rowNumber}-{v.projectLeadEmail}
          </p>
        )
      }
      if (keyName === 'invalidWorkStreams') {
        return (
          <p>
            Row {v.rowNumber}-{v.workstream}
          </p>
        )
      }
      if (keyName === 'invalidClientLevel') {
        return (
          <p>
            Row {v.rowNumber}-{v.clientLevel}
          </p>
        )
      }
      if (keyName === 'invalidSalutation') {
        return (
          <p>
            Row {v.rowNumber}-{v.invalidSalutation}
          </p>
        )
      }
      if (keyName === 'invalidModeOfSurvey') {
        return (
          <p>
            Row {v.rowNumber}-{v.invalidModeOfSurvey}
          </p>
        )
      }
      if (keyName === 'invalidTranfEngaged') {
        return (
          <p>
            Row {v.rowNumber}-{v.invalidTranfEngaged}
          </p>
        )
      }
      if (keyName === 'invalidContactNumbers') {
        return (
          <p>
            Row {v.rowNumber}-{v.invalidContactNumber}
          </p>
        )
      }
      return ''
    })
  }
  const renderErrorMessage = (errorMessage) => {
    return (
      <>
        {errorMessage.length > 0 && (
          <div className="error-messages">
            {errorMessage.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        )}
      </>
    )
  }
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }
  const setFileName = async (result, e) => {
    try {
      const invalidMetaData = []
      const invalidEmails = []
      const inValidCsEmail = []
      const inValidInternalSurvey = []
      const invalidTenures = []
      const invalidRevenues = []
      const invalidGenericTypes = []
      const invalidCountries = []
      const invalidDesignations = []
      const inValidProjectLeads = []
      const invalidCs = []
      let existedClientName = []
      const invalidWorkStreams = []
      const inValidLeadEmail = []
      const inValidInternalSurveyEmail = []
      const invalidClientLevel = []
      const invalidContactNumbers = []
      const updatedRecords =[]
      const invalidTranfEngaged = []
      const invalidModeOfSurvey=[]
      const invalidSalutation = []

      let seenCombinations = new Map();
  
      function checkForDuplicates(item) {
        const combinationKey = `${item.vertical}_${item.account}_${item.project}_${item.clientEmail}`.replace(/\s+/g, '').toLowerCase();
        const isInUploadTemplateData = csatData.some((metaData) => {
          const metaCombinationKey = `${metaData.vertical}_${metaData.account}_${metaData.project}_${metaData.client_email}`.replace(/\s+/g, '').toLowerCase();
          return combinationKey === metaCombinationKey;
        });

        if (seenCombinations.has(combinationKey) || isInUploadTemplateData) {
          existedClientName.push({
            duplicateClientName: item.clientEmail,
            rowNumber: item.rowIndex,
          });
        } else {
          seenCombinations.set(combinationKey, true);
        }
      }

      // const handleContactValidation = (contactNumber) => {
      //   const phoneRegex = /^\+[1-9]\d{1,14}$/;
      //   return phoneRegex.test(contactNumber);
      // };
    
      const processedData = await Promise.all(
        result.map(async (item, index) => {
          const matchedItem = uploadTemplateData.find((metaData) => {
            return item.uniqueId === metaData.unique_id
          })
          if (matchedItem) {
            if (
              matchedItem.vertical === item.vertical &&
              matchedItem.account === item.account &&
              matchedItem.project === item.project &&
              (!item.workStream || matchedItem.workstream === item.workStream)
            ) {
              if (!validateEmail(item.clientEmail)) {
                invalidEmails.push({
                  clientEmail: item.clientEmail,
                  rowNumber: item.rowIndex,
                })
              }
              if (!validateEmail(item.csEmail)) {
                inValidCsEmail.push({
                  csEmail: item.csEmail,
                  rowNumber: item.rowIndex,
                })
              }
              if(!validateEmail(item.internalSurveyee)) {
                inValidInternalSurveyEmail.push({
                  isEmail: item.internalSurveyee,
                  rowNumber: item.rowIndex,
                })
              }
              if(item.projectLeadEmail){
              if (!validateEmail(item.projectLeadEmail)) {
                inValidLeadEmail.push({
                  projectLeadEmail: item.projectLeadEmail,
                  rowNumber: item.rowIndex,
                })
              }
              if (
                !employeeEmails.includes(item.projectLeadEmail)
              ) {
                inValidProjectLeads.push({
                  projectLead: item.projectLeadEmail,
                  rowNumber: item.rowIndex,
                })
              }
             }
              if (!employeeEmails.includes(item?.csEmail?.toLowerCase())) {
                invalidCs.push({
                  invalidCs: item?.csEmail,
                  rowNumber: item.rowIndex,
                })
              }
              if (
                !employeeEmails.includes( item?.internalSurveyee?.toLowerCase())
              ) {
                inValidInternalSurvey.push({
                  internalSurveyee: item.internalSurveyee,
                  rowNumber: item.rowIndex,
                })
              }
              const countriesSet = new Set(countriesOptions.map((country) => country.toLowerCase()));
              if (!countriesSet.has(item.clientBaseLocationCountry.toLowerCase())) {
                invalidCountries.push({
                  clientLocation: item.clientBaseLocationCountry,
                  rowNumber: item.rowIndex,
                })
              }
              if (!revenuesOptions.includes(item.revenueBucket)) {
                invalidRevenues.push({
                  revenue: item.revenueBucket,
                  rowNumber: item.rowIndex,
                })
              }
              if (!csatoptionsdata.ClientLevel.includes(item.clientLevel)) {
                invalidClientLevel.push({
                  clientLevel: item.clientLevel,
                  rowNumber: item.rowIndex,
                })
              }
              if (!tenures.includes(item.clientTenure)) {
                invalidTenures.push({
                  tenure: item.clientTenure,
                  rowNumber: item.rowIndex,
                })
              }
              if (
                !(
                  item.surveyType == 'Generic' ||
                  item.surveyType == 'Solution Support'
                )
              ) {
                invalidGenericTypes.push({
                  invalidSurveyType: item.surveyType,
                  rowNumber: item.rowIndex,
                })
              }
              if (
                !csatoptionsdata?.TransormationalEngaged?.includes(item.transFarmationalEngaged)
              ) {
                invalidTranfEngaged.push({
                  invalidTranfEngaged: item.transFarmationalEngaged,
                  rowNumber: item.rowIndex,
                })
              }

              if (!csatoptionsdata?.Salutation?.includes(item.salutation)) {
                invalidSalutation.push({
                  invalidSalutation: item.salutation,
                  rowNumber: item.rowIndex,
                });
              }
              
              if (!csatoptionsdata?.ModeOfSurvey?.includes(item.modeOfSurvey)) {
                invalidModeOfSurvey.push({
                  invalidModeOfSurvey: item.modeOfSurvey,
                  rowNumber: item.rowIndex,
                });
              }

              if (!csatoptionsdata?.ModeOfSurvey?.includes(item.modeOfSurvey)) {
                invalidModeOfSurvey.push({
                  invalidModeOfSurvey: item.modeOfSurvey,
                  rowNumber: item.rowIndex,
                });
              }
              // if (item?.contactNumber && !handleContactValidation(item?.contactNumber)) {
              //   invalidContactNumbers.push({
              //     invalidContactNumber: item.contactNumber,
              //     rowNumber: item.rowIndex,
              //   })
              // }
              
              // if (
              //   !(
              //     item.areYouDirectlyEngagedInWorkWithThisClient.toLowerCase() ==
              //       'yes' ||
              //     item.areYouDirectlyEngagedInWorkWithThisClient.toLowerCase() ==
              //       'no'
              //   )
              // ) {
              //   invalidEngagement.push({
              //     directClient: item.areYouDirectlyEngagedInWorkWithThisClient,
              //     rowNumber: item.rowIndex,
              //   })
              // }
              checkForDuplicates(item);
            } else {
              e.target.value = ''
              setisDisabled(true)
              if ( matchedItem.vertical !== item.vertical ||
                matchedItem.account !== item.account ||
                matchedItem.project !== item.project ||matchedItem.workstream && matchedItem.workstream !== item.workStream){
                  invalidMetaData.push({
                  metaData: matchedItem,
                  item :item,
                  rowNumber: item.rowIndex,
                })
              } 
               if (!matchedItem.workstream && item.workStream){
                invalidWorkStreams.push({
                  workstream: item.workStream,
                  rowNumber: item.rowIndex,
                })
              }
             }
             if (
              invalidWorkStreams.length > 0 ||
              invalidMetaData.length > 0 ||
              invalidEmails.length > 0 ||
              inValidCsEmail.length > 0 ||
              inValidInternalSurveyEmail.length>0||
              invalidGenericTypes.length > 0 ||
              invalidCountries.length > 0 ||
              invalidRevenues.length > 0 ||
              invalidTenures.length > 0 ||
              inValidProjectLeads.length > 0 ||
              existedClientName.length > 0||  
              invalidClientLevel.length > 0 ||
              invalidSalutation.length > 0||
              invalidModeOfSurvey.length>0 ||
              invalidTranfEngaged.length>0||
              invalidContactNumbers.length>0
            ) {
              setOpenAlert(true);
              let errorMessage = [];
              e.target.value = '';
              if (invalidWorkStreams.length > 0) {
                errorMessage.push(
                  <div key="existedClientName">
                    <strong>{`There is no matching workstream found.`}</strong>
                    {setInvalidMessageContent(invalidWorkStreams, 'invalidWorkStreams')}
                  </div>
                );
              }
              if (invalidMetaData.length > 0) {
                errorMessage.push(
                  <div key="invalidMetaData">
                    <strong>{`Following data doesn't match with the database:`}</strong>
                    {setInvalidMessageContent(invalidMetaData, 'metaData', item)}
                  </div>
                );
              }else {
          
              if (
                invalidEmails.length > 0 ||
                inValidCsEmail.length > 0 ||
                inValidLeadEmail.length > 0||
                inValidInternalSurveyEmail.length>0
              ) {
                errorMessage.push(
                  <div style={{fontSize:'13px'}} key="invalidEmails">
                    <strong>{`Invalid Emails:`}</strong>
                    {invalidEmails.length > 0 &&
                      <div style={{fontSize:'11px', marginLeft:'8px'}} key="invalidEmails">
                        <strong>{`Client Email:`}</strong> {setInvalidMessageContent(invalidEmails, 'invalidClientEmail')}</div>
                    }
                      {inValidCsEmail.length > 0 &&
                      <div style={{fontSize:'11px', marginLeft:'8px'}} key="invalidEmails">
                        <strong>{`CS Email:`}</strong>{setInvalidMessageContent(inValidCsEmail, 'invalidCsEmail')}</div>
                    }
                     {inValidLeadEmail.length > 0 &&
                      <div style={{fontSize:'11px', marginLeft:'8px'}} key="invalidLeadEmail">
                        <strong>{`Project Lead:`}</strong>{setInvalidMessageContent(inValidLeadEmail, 'inValidLeadEmail')}</div>
                    }
                       {inValidInternalSurveyEmail.length > 0 &&
                      <div style={{fontSize:'11px', marginLeft:'8px'}} key="inValidInternalSurveyEmail">
                        <strong>{`Internal Surveyee:`}</strong>{setInvalidMessageContent(inValidInternalSurveyEmail, 'inValidInternalSurveyEmail')}</div>
                    }
                  </div>
                );
              }else{ 
                if (existedClientName.length > 0) {
                  errorMessage.push(
                    <div key="existedClientName">
                      <strong>{`Client is already nominated!`}</strong>
                      {setInvalidMessageContent(existedClientName, 'existedClientName')}
                    </div>
                  );
                }
                if (
                invalidCountries.length > 0 ||
                invalidRevenues.length > 0 ||
                invalidTenures.length > 0 ||
                inValidInternalSurvey.length > 0 ||
                invalidGenericTypes.length > 0 ||
                invalidCs.length > 0 ||
                inValidProjectLeads.length > 0 ||
                invalidClientLevel.length > 0 ||
                invalidSalutation.length > 0||
                invalidModeOfSurvey.length>0 ||
                invalidTranfEngaged.length>0||
                invalidContactNumbers.length>0

              ) {        
                errorMessage.push(
                  <div style={{fontSize:'13px'}} key="otherInvalidData">
                    <strong>{`Following data doesn't match with the database:`}</strong>
                    {invalidCountries.length > 0 &&
                      <div style={{fontSize:'11px', marginLeft:'8px'}} key="invalidCountries">
                        <strong>{`Client Location:`}</strong>
                        {setInvalidMessageContent(invalidCountries, 'invalidCountries')}
                      </div>
                    }
                    {invalidRevenues.length > 0 &&
                      <div style={{fontSize:'11px', marginLeft:'8px'}} key="invalidRevenues">
                        <strong>{`Revenue:`}</strong>
                        {setInvalidMessageContent(invalidRevenues, 'invalidRevenues')}
                      </div>
                    }
                    {invalidTenures.length > 0 &&
                      <div key="invalidTenures" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Tenure:`}</strong>
                        {setInvalidMessageContent(invalidTenures, 'invalidTenures')}
                      </div>
                    }
                    {inValidInternalSurvey.length > 0 &&
                      <div key="inValidInternalSurvey" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Internal Surveyee:`}</strong>
                        {setInvalidMessageContent(inValidInternalSurvey, 'inValidInternalSurvey')}
                      </div>
                    }
                    
                    {invalidGenericTypes.length > 0 &&
                      <div key="invalidGenericTypes" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Survey Type:`}</strong>
                        {setInvalidMessageContent(invalidGenericTypes, 'invalidGenericTypes')}
                      </div>
                    }
                    {invalidCs.length > 0 &&
                      <div key="invalidCs" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`CS Email:`}</strong>
                        {setInvalidMessageContent(invalidCs, 'invalidCs')}
                      </div>
                    }
                     {inValidProjectLeads.length > 0 &&
                      <div key="invalidCs" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Project Lead:`}</strong>{setInvalidMessageContent(inValidProjectLeads, 'inValidProjectLeads')}</div>
                     }
                     {invalidClientLevel.length > 0 &&
                      <div key="invalidCs" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Client Level:`}</strong>{setInvalidMessageContent(invalidClientLevel, 'invalidClientLevel')}</div>
                      }
                       {invalidTranfEngaged.length > 0 &&
                      <div key="invalidCs" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Transformational :`}</strong>{setInvalidMessageContent(invalidTranfEngaged, 'invalidTranfEngaged')}</div>
                      }
                       {invalidModeOfSurvey.length > 0 &&
                      <div key="invalidCs" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Mode Of Survey:`}</strong>{setInvalidMessageContent(invalidModeOfSurvey, 'invalidModeOfSurvey')}</div>
                      }
                       {invalidSalutation.length > 0 &&
                      <div key="invalidCs" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Salutation:`}</strong>{setInvalidMessageContent(invalidSalutation, 'invalidSalutation')}</div>
                      }
                        {invalidContactNumbers.length > 0 &&
                      <div key="invalidCs" style={{fontSize:'11px', marginLeft:'8px'}}>
                        <strong>{`Contact Number:`}</strong>{setInvalidMessageContent(invalidContactNumbers, 'invalidContactNumbers')}</div>
                      }
                  </div>
                );
              
              }
            }
            }
              const invalidMessage = renderErrorMessage(errorMessage);
              setMessage(invalidMessage);
              setisDisabled(true);
            }else {
              setIsValidData(true)
              setisDisabled(false)
              item.verticalId = matchedItem.vertical_id
              item.accountId = matchedItem.account_id
              item.salesForceId = matchedItem.salesforce_opportunity_id
              item.created_by = userDetails.employee_id;
              item.createdAt = new Date();
              return item
            }
          }
        })
      )
      setReqDta(processedData)
      //setRecordsToUpdate(updatedRecords)
    } catch (error) {
      console.error('Error in processing data', error)
      setisDisabled(true)
    }
  }

  const setMessageContent = (
    missingFields,
    dateIncorrectFields,
    messageContent,
    fieldNames
  ) => {
    if (missingFields.length > 0) {
      return (
        <div key="missingFields">
          <h4>{messageContent}</h4>
          <ul>
            {missingFields.map((rowInfo) => (
              <li key={rowInfo.rowNumber}>
                <h5>Row {rowInfo.rowNumber}:</h5>{' '}
                {rowInfo.missingFields.join(', ')}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    return null
  }

  const setDeletedColumnContent = (missingFields, messageContent) => {
    if (missingFields.length > 0) {
      return (
        <div key="missingFields">
          <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
            {messageContent}
          </div>
          <ul style={{ marginLeft:'16px' }}>
            {missingFields.map((rowInfo, index) => (
              <li style={{ fontWeight: 'bold', fontSize: '11px', }} key={index}>
                {rowInfo}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    return null
  }
  const mandatoryColumns = [
    'Salutation',
    'Client First Name',
    'Client Last Name',
    'Client Preferred Name',
    'Client Email',
    'Client Designation',
    'Client Base Location (Country)',
    'Client Tenure',
    'Internal Surveyee',
    'CS Email',
    'Client LinkedIn',
    'Revenue Bucket',
    'Survey Type',
    'Transformational Engaged',
    'Mode Of survey',
    'Client Level',
    'Service Line',
  ];
  
  const getColumnValues = async (event, callback) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    if (fileExtension === 'xlsx') {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target.result;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);
        const worksheet = workbook.worksheets[0];
        const headerValues = worksheet
          .getRow(1)
          .values.filter((value) => value !== null)
          .map((value) => {
            if (typeof value === 'object' && value.richText) {
              return value.richText.map((item) => item.text).join('');
            } else {
              return value;
            }
          });
        callback(headerValues);
      };
      reader.readAsArrayBuffer(file);
    } else if (fileExtension === 'csv') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            const headerValues = results.meta.fields;
            callback(headerValues);
          },
        });
      };
      reader.readAsText(file);
    } else {
      setOpenAlert(true)
      setMessage('Invalid file.Please check the file format.')
      event.target.value = ''
      console.error('Unsupported file type:', fileExtension);
    }
  };
  
  const validateFileData = async (results, e) => {
    // results.data = results.data?.filter(
    //   (row) => !Object.values(row).every((value) => value === null)
    // )
    setisDisabled(true)
    setIsValidData(false)
    if (results.data?.length === 0) {
      setOpenAlert(true)
      setMessage(
        'Uploaded file is empty. Please download the template, update & upload.'
      )
      e.target.value = ''
    } else if (results.errors?.length > 0) {
      setOpenAlert(true)
      setMessage('Invalid file.Please check the file format.')
      e.target.value = ''
    } else if (results.data.length > 0) {
      const headerValues = await new Promise((resolve, reject) => {
        getColumnValues(e, (headerValues) => {
          resolve(headerValues)
        })
      })

      const deletedHeaders = columnNames.filter(
        (column) => !headerValues.includes(column)
      )

      setDeletedHeaderValues(deletedHeaders)
      if (deletedHeaders.length > 0) {
        setOpenAlert(true)
        const text = `Column(s) from the template are missing:`
        const missingFieldsContent = setDeletedColumnContent(
          deletedHeaders,
          text
        )
        setMessage(missingFieldsContent)

        e.target.value = ''
      } else {
        const resultedData = results.data.map((item, index) => ({
          rowIndex: item['Row Id'] + 1,
          vertical: item['Vertical*'],
          account: item['Account*'],
          project: item['Project*'],
          workStream: item['Work Stream'],
          projectLeadEmail: item['Project Lead Email'],
          csEmail: item['CS Email*'],
          internalSurveyee: item['Internal Surveyee*'],
          clientFirstName: item['Client First Name*'],
          clientLastName: item['Client Last Name*'],
          clientEmail: item['Client Email*'],
          clientDesignation: item['Client Designation*'],
          clientPreferredName: item['Client Preferred Name*'],
          clientTenure: item['Client Tenure*'],
          clientBaseLocationCountry: item['Client Base Location (Country)*'],
          clientLinkedin: item['Client LinkedIn'],
          revenueBucket: item['Revenue Bucket*'],
          surveyType: item['Survey Type*'],
          transFarmationalEngaged:item['Transformational Engaged*'],
          modeOfSurvey:item['Mode Of Survey*'],
          clientLevel: item['Client Level*'],
          servicesRendered: item['Service Line*'],
          uniqueId: item['Unique Id*'],
          salutation:item['Salutation*'],
          alternateClientName: item['Alternate Client Name'],
          alternateclientEmailId: item['Alternate Client Email'],
          contactNumber:item['Contact Number']
        }));
        
        const filledData = resultedData
          .map((item) => {
            const newItem = {}
            Object.keys(item).forEach((key) => {
              newItem[key.replace('*', '')] = item[key]
            })
            return newItem
          })
          .filter(
            ({
              rowIndex,
              vertical,
              account,
              project,
              workStream,
              uniqueId,
              projectLeadEmail,
              clientLinkedin,
              alternateClientName,
              alternateclientEmailId,
              ...rest
            }) => {
              const restValues = Object.values(rest)
              return restValues.some(
                (value) => value !== null && value !== '' && value !== undefined
              )
            }
          )

        const unfilledData = resultedData.map((item) => {
          const newItem = {}
          Object.keys(item).forEach((key) => {
            newItem[key.replace('*', '')] = item[key]
          })
          return newItem
        })
        const modifiedData = unfilledData.map(
          ({
            rowIndex,
            vertical,
            account,
            project,
            workStream,
            uniqueId,
            projectLeadEmail,
            clientLinkedin,
            alternateClientName,
            alternateclientEmailId,
            ...rest
          }) => rest
        )
        const filteredData = modifiedData.filter((obj) => {
          return Object.values(obj).every(
            (value) => value === null || value === '' || value === undefined
          )
        })
        if (filledData.length > 0) {
          let isValid = false
          const rowsWithMissingFields = []
          for (let i = 0; i < filledData.length; i++) {
            const row = filledData[i]
            let fields = Object.keys(row)
            const excludedFields = [
              'workStream',
              'projectLeadEmail',
              'clientLinkedin','contactNumber', 'alternateClientName',
              'alternateclientEmailId',
            ]
            fields = fields.filter((field) => !excludedFields.includes(field))
            const missingFields = fields?.filter((field) => !row[field])
            if (missingFields?.length > 0) {
              isValid = false
              rowsWithMissingFields.push({
                rowNumber: row.rowIndex,
                missingFields,
              })
            } else {
              isValid = true
            }
          }
          if (isValid) {
            setFileName(filledData, e)
          } else {
            setisDisabled(true)
            let alertMessage = ''
            if (rowsWithMissingFields.length > 0) {
              const text = 'Following rows have missing fields:'
              const missingFieldsContent = setMessageContent(
                rowsWithMissingFields,
                [],
                text,
                {}
              )
              alertMessage = missingFieldsContent
              setOpenAlert(true)
              setMessage(alertMessage)
            } else {
              alertMessage = 'No data provided or all rows are missing fields.'
            }
            setOpenAlert(true)
            setMessage(alertMessage)
            e.target.value = ''
          }
        } else if (filteredData.length > 0) {
          setOpenAlert(true)
          const headerSent = 'Please fill the mandatory fields:'

          const mandatoryFieldsContent = setDeletedColumnContent(
            mandatoryColumns,
            headerSent
          )
          setMessage(mandatoryFieldsContent)
          e.target.value = ''
        }
      }
    } else {
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const isExcelFile =
        file.name.endsWith('.xlsx')
        setReqDta([])
      if (isExcelFile) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          const sheetName = workbook.SheetNames[0]
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
          const csvData = Papa.unparse(sheetData)
          Papa.parse(csvData, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              validateFileData(results, e)
            },
          })
        }

        reader.readAsArrayBuffer(file)
      } else if (file.name.endsWith('.csv')) {
        const reader = new FileReader()
        reader.onload = async (event) => {
          const csvData = event.target.result
          Papa.parse(csvData, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              validateFileData(results, e)
            },
          })
        }
        reader.readAsText(file)
      } else {
        setOpenAlert(true)
        setMessage('Invalid file.Please check the file format.')
        e.target.value = ''
        console.log('Unsupported file type')
      }
    }
  }

  return (
    <>
      <Dialog
        open={props.openBulkUploadModel}
        onClose={closeBulkUploadModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        className="filesmodal"
        sx={{ height: '600px' }}
      >
        <DialogTitle style={{ fontSize: '1rem' }} id="alert-dialog-title">
        Bulk Upload - CSAT nominations
        </DialogTitle>
        <DialogContent style={{ marginBottom: '5px' }}>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '14px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
             Click here to download the template.
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ExcelGenerator
              data={uploadTemplateData}
              uniqueEmployees={uniqueEmployees}
              revenuesOptions={revenuesOptions}
              designations={designationOptions}
              countries={countriesOptions}
            />
          </div>
          <div
            style={{
              fontSize: '12px',
              marginTop: '14px',
              marginBottom: '14px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
           Click here to upload the data.
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                border: '1px solid black',
                padding: '5px',
                margin: '2px',
                maxWidth: 'max-content',
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                accept=".csv,.xlsx,.xls,.xlsm"
                style={{ border: 'none', flex: 1 }}
                onClick={handleInputClick}
                onChange={(e) => {
                  handleFileChange(e)
                }}
              />
            </div>
          </div>
          <div
            style={{
              fontSize: '10px',
              margin:"2px",
              display: 'flex',
              justifyContent: 'center',
              marginLeft:'-130px'
            }}
          >*Required format: .xlsx/csv </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="end">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                className="primarybtn"
                onClick={handleFileUpload}
                disabled={isDisabled}
              >
                Upload
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={closeBulkUploadModel}
                color="primary"
                autoFocus
                className="primarybtntext"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {props.openBulkUploadModel && openAlert && (
          <Dialog
            open={props.openBulkUploadModel && openAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xs"
            className="filesmodal"
          >
            {' '}
            <IconButton
              aria-label="close"
              onClick={closeAlert}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon style={{ fontSize: '14' }} />
            </IconButton>
            <DialogContent style={{ fontSize: '12px' }}>
              <div
                style={{
                  padding: '5px',
                  margin: '2px',
                  maxWidth: 'max-content',
                  wordWrap: 'break-word',
                }}
              >
                {message}
              </div>
            </DialogContent>
          </Dialog>
        )}
        <Loading open={openBackdrop} />
      </Dialog>
    </>
  )
}

export default CsatBulkUpload
