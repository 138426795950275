import React from 'react'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { Button } from '@mui/material'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const ExcelGenerator = (props) => {
  const { data } =
    props
    const {
      data: riskOptionData,
    } = useSelector((state) => state.risk)

    const generateExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet('Sheet1');
      let worksheetMasterData = workbook.addWorksheet('MasterData');
    
      const columnNames = [
        '#',
        'Vertical',
        'Account',
        'Project',
        'Workstream',
        'Category',
        'Risk Description',
        'Consequence',
        'Existing Controls/Measures',
        'Likelihood',
        'Impact',
        'Risk Response',
        'Risk Response Details',
        'Risk Owner',
        'Status',
        'ETA',
        'Unique_Id',
      ];
    
      worksheet.addRow(columnNames);
    
      columnNames.forEach((columnName, index) => {
        if (columnName !== '#' && columnName !== 'Workstream'&& columnName !== 'Existing Controls/Measures' && columnName !==  'Risk Response' && columnName !== 'Risk Response Details') {
          const cell = worksheet.getCell(1, index + 1);
          const richText = [
            { text: columnName },
            { font: { color: { argb: 'FF0000' } }, text: '*' },
          ];
          cell.value = { richText };
        }
      });
    
      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? String(cell.value).length : 0;
          if (length > maxLength) {
            maxLength = length;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      });
    
      const templateData = data.map((item, index) => [
        index + 1,
        item.vertical,
        item.account,
        item.project,
        item.workstream,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Open',
        '',
        item.unique_id,
      ]);
    
      templateData.forEach((data) => {
        worksheet.addRow(data);
      });
    
      const riskResponseOptionData =(riskOptionData && riskOptionData.risk_response_options.length>0)? `"${riskOptionData.risk_response_options.join(',')}"`:"";
      const riskStatusOptionData =(riskOptionData && riskOptionData.risk_status_options.length>0)? `"${riskOptionData.risk_status_options?.join(',')}"`:"";
      // adding values in excel column and fetching valus for list from the column - for category
      riskOptionData && riskOptionData?.category_options?.forEach((value, index) => {
        worksheet.getCell(`AB${index + 1}`).value = value;
      });
      riskOptionData && riskOptionData?.category_options?.forEach((value, index) => {
        worksheet.getCell(`AB${index+ 19 +1}`).value = value;
      });
      const formulaeValues = `$AB$1:$AB$${riskOptionData?.category_options?.length+19}`

      for (let i = 2; i <= data.length + 1; i++) {
        worksheet.getCell(`F${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: [formulaeValues],
        };
        worksheet.getCell(`J${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ['"High,Medium,Low"'],
        };
        worksheet.getCell(`K${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ['"High,Medium,Low"'],
        };
        worksheet.getCell(`L${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: [riskResponseOptionData],
        };
        worksheet.getCell(`O${i}`).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: [riskStatusOptionData],
        };
  
        worksheet.getCell(`P${i}`).dataValidation = {
          type: 'date',
          operator: 'lessThan',
          showErrorMessage: true,
          allowBlank: true,
          errorTitle: 'Invalid Date Format',
          error: 'Please enter valid date.',
        }

        worksheet.getCell(`P${i}`).note = {
          texts: [
            {
              font: { size: 9, name: 'Poppins', family: 2, scheme: 'minor' },
              text: 'Please ',
            },
            {
              font: { size: 9, name: 'Poppins', scheme: 'minor' },
              text: 'enter ',
            },
            {
              font: { size: 9, name: 'Poppins', family: 2, scheme: 'minor' },
              text: 'the ',
            },
            {
              font: { size: 9, name: 'Poppins', scheme: 'minor' },
              text: 'date ',
            },
            {
              font: { size: 9, name: 'Poppins', family: 2, scheme: 'minor' },
              text: 'in ',
            },
            {
              font: { size: 8, name: 'Poppins', scheme: 'minor' },
              text: 'MM/DD/YYYY ',
            },
            {
              font: { size: 9, name: 'Poppins', family: 2, scheme: 'minor' },
              text: 'format ',
            },
          ],
          margins: {
            insetmode: 'custom',
            inset: [0.00, 0.00, 0.0, 0.0],
          },
          editAs: 'twoCells',
        }
      }

      worksheet.getColumn(17).hidden = true;
      worksheet.getColumn(28).hidden = true;
      worksheetMasterData.state = 'veryHidden';
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), 'Risks Template.xlsx');
    };
    
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ borderRadius: '15px' }}
        onClick={generateExcel}
      >
        Download Template
      </Button>
    </div>
  )
}

export default ExcelGenerator     