import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  Box,
  Button,
  Grid,
  Popper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import FilterComponent from '../../Common/multiSelectCustomFilter'

const useToolbarStyles = makeStyles((theme) => ({
  customToolbarActions: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
}))

export default function CustomToolbar(props) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const toolbarclasses = useToolbarStyles()
  return (
    <div className={toolbarclasses.customToolbarActions}>
      <Grid
        container
        style={
          isSmallScreen
            ? {
                justifyContent: 'flex-start',
                position: 'sticky',
                top: '0', // Adjust as needed to position the content vertically
                left: '0', // Adjust as needed to position the content horizontally
                zIndex: 1000, // Ensure it's above other content
                display: 'inline-flex',
                width: 'auto',
              }
            : { justifyContent: 'flex-end', paddingTop: '0.6rem' }
        }
      >
        <Grid item>
          <Tooltip title={'Click here to filter the data'} arrow>
            <FilterListIcon
              variant="contained"
              color="primary"
              onClick={props?.handleClick}
              sx={{
                // margin: '-0.5rem',
                marginLeft: '0.45rem',
                marginRight: '0.45rem',
                marginTop: '0.30rem',
                cursor: 'pointer',
              }}
            ></FilterListIcon>
          </Tooltip>
          <Popper id={props?.id} open={props?.open} anchorEl={props?.anchorEl}>
            <Box sx={{ p: 1.2, backgroundColor: '#f9f9f9' }}>
              <FilterComponent
                keyName={'csatRevieweeAdh'}
                setAnchorEl={props?.setAnchorEl}
                excludeFilter={props?.excludeFilter ? 'yes' : 'no'}
              />
            </Box>
          </Popper>
        </Grid>
        {/* <Grid item>
        {props?.userDetails.csatAdmin?.toLowerCase() === 'yes' ? 
            <Tooltip title={'Upload CSAT Nominations'}>
              <Button //  when nomination window is closed open only for admin--->
              >
                <CloudUploadIcon
                  variant="contained"
                  // window closed--->
                  color={
                    props?.userDetails.role == 'Admin' ? 'primary' : 'grey'
                  }
                  onClick={props?.handleUploadOpen}
                />
              </Button>
            </Tooltip>:''}
          
        </Grid> */}
        <Grid item>
          <Tooltip title={'Download Current Nominations'} arrow>
            <Button onClick={props?.handleDownload}>
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button
            //  when nomination window is closed--->
            // disabled={
            //   // (props?.userDetails.role == 'Reviewer' &&
            //   //   props?.userDetails?.vertical_ids?.verticalIds?.length > 0)    ||
            //   props?.userDetails.role == 'Admin' ? false : true
            // }
            variant="contained"
            color="primary"
            onClick={props?.handleFormOpen}
          >
            Add/Nominate Client
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
