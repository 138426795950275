import { Edit } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Box, Button, Grid, Switch, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import Popover from '@mui/material/Popover'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Loading from '../../../Common/Loading'
import {
  fetchRiskOptions,
  getAccountGenericRiskrecods,
  getRiskrecods,
} from '../../../redux/reducer/riskSlice'
import { getAllMetaData } from '../../../services/riskManagementService'
import ReadmeFile from './ReadmeFile'
import RiskForm from './RiskForm'
import RiskTable from './Risktable'
import {
  updateFilterData,
  updateRiskInitialData,
} from '../../../redux/reducer/projectHealthFilterSlice'
import { updateSelectedRisksData } from '../../../redux/reducer/riskSlice'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Popper, useMediaQuery } from '@mui/material'
import FilterComponent from '../ProjectHealth/Common/multiSelectCustomFilter'
import { useTheme } from '@emotion/react'
import RisksBulkUpload from '../../View/RiskLogs/RisksBulkUpload'

const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    leaveTouchDelay={3000}
    enterTouchDelay={20}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 600,
    maxWidth: 160,
    borderRadius: 3,
  },
}))
const RiskManagement = (props) => {
  const dispatch = useDispatch()
  const { data: adminData } = useSelector((state) => state.admin)
  const { userDetails } = useSelector((state) => state.user)
  const [openForm, setOpenForm] = useState(false)
  const [openReadme, setOpenReadme] = useState(false)
  const [currentData, setCurrentData] = useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [uniqueVertical, setUniqueVertical] = useState([])
  const [uniqueAccount, setUniqueAccount] = useState([])
  const [editForm, setEditForm] = useState(false)
  const [editRowIndex, setEditRowIndex] = useState(null)
  const [rows, setRows] = useState([])
  const [uploadOpen, setUploadOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const [openAllRiskData, setOpenAllRiskData] = useState(false)
  const [allRiskDetails, setAllRiskDetails] = useState('')

  const {
    riskData,
    selectedRisksData,
    accountgenericriskData,
    isLoadingaccountgeneicRisk,
    isLoadingRisk,
  } = useSelector((state) => state.risk)
  const { riskInitialData, filterData } = useSelector((state) => state.filter)
  const [accountgenericfilter, setAccountGenericFilter] = useState('No')
  const [loading, setLoading] = React.useState(false)

  const handleFormOpen = () => {
    setOpenForm(true)
    setSelectedRows([])
  }

  const handleClick = (event) => {
    setSelectedRows([])
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleFormClose = () => {
    setOpenForm(false)
    setEditForm(false)
    setEditRowIndex(null)
  }
  function onClose() {
    handleFormClose()
  }
  const getMetaData = () => {
    setLoading(true)
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    getAllMetaData(data)
      .then((res) => {
        if (res.success) {
          setCurrentData(res.data.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleAllRisksDataClose = () => {
    setOpenAllRiskData(false)
  }

  const formatDate = (isoString) => {
    const date = new Date(isoString)

    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(date)

    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date)

    return `${formattedDate}, ${formattedTime}`
  }

  const showAllTheData = (rowData, event) => {
    setOpenAllRiskData(true)
    const getRiskOwnerCellContent = (adminData, rowData) => {
      const { employeeDetails } = adminData || {}
      const emailId = rowData?.risk_owner.toLowerCase()
      const employee = employeeDetails?.find(
        (option) => option?.email_id === emailId
      )
      const cleanedEmail = emailId?.replace(/(@tredence\.com|\d+|\.)/g, ' ')?.trim()
      const employeeName = employee?.employee_name || cleanedEmail || ''
      return (
        <>
          <Tooltip title={employeeName ? employee?.email_id : ''}>
            <span
              style={{
                cursor: 'pointer',
              }}
            >
              {employeeName}
            </span>
          </Tooltip>
        </>
      )
    }

    const details = (
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <table
          style={{
            width: '100%',
            tableLayout: 'fixed',
            borderCollapse: 'collapse',
          }}
        >
          <tbody>
            {accountgenericfilter != 'Yes' && (
              <>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '105px' }}>
                    Project
                  </td>
                  <td>
                    <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                      :
                    </span>
                    <span>
                      {rowData.project ? (
                        rowData.project
                      ) : (
                        <span style={{ paddingLeft: '2px' }}>-</span>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '105px' }}>
                    Workstream
                  </td>
                  <td>
                    <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                      :
                    </span>
                    {rowData.workstream ? (
                      rowData.workstream
                    ) : (
                      <span style={{ paddingLeft: '2px' }}>-</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" style={{ height: '14px' }}></td>
                </tr>
              </>
            )}
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                Risk Category
              </td>
              <td>
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                <span>
                  {rowData?.category?.trim() ? (
                    rowData?.category
                  ) : (
                    <span style={{ paddingLeft: '2px' }}>-</span>
                  )}
                </span>
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                Risk Description
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '1.5',
                }}
              >
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.risk_description?.trim() ? (
                  rowData?.risk_description
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                Consequence(s)
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '1.5',
                }}
              >
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.consequence?.trim() ? (
                  rowData?.consequence
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                {' '}
                Existing controls or measures
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '1.5',
                }}
              >
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.measure?.trim() ? (
                  rowData?.measure
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td style={{ fontWeight: 'bold', width: '105px' }}>Likelihood</td>
              <td>
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.likelihood ? (
                  rowData?.likelihood
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td style={{ fontWeight: 'bold', width: '105px' }}>Impact</td>
              <td>
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.impact ? (
                  rowData?.impact
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td style={{ fontWeight: 'bold', width: '105px' }}>Risk Level</td>
              <td>
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.risk_level ? (
                  rowData?.risk_level
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                {' '}
                Risk Response
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '1.5',
                }}
              >
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.risk_response?.trim() ? (
                  rowData?.risk_response
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                {' '}
                Risk Owner
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '1.5',
                }}
              >
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.risk_owner?.trim() ? (
                  getRiskOwnerCellContent(adminData, rowData)
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td style={{ fontWeight: 'bold', width: '105px' }}>Status</td>
              <td>
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.risk_status ? (
                  rowData?.risk_status
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td style={{ fontWeight: 'bold', width: '105px' }}>Eta</td>
              <td>
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.eta ? (
                  rowData?.eta
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                {' '}
                Risk Response Details
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '1.5',
                }}
              >
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.risk_response_details?.trim() ? (
                  rowData?.risk_response_details
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                {' '}
                created By
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  lineHeight: '1.5',
                }}
              >
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.created_by ? (
                    adminData.employeeDetails.find(
                       (emp) => rowData?.created_by === emp.employee_id
                     )?.employee_name
                ) : (
                  <span style={{ paddingLeft: '2px' }}>Admin</span>
                )}
              </td>
            </tr>
            <tr style={{ marginBottom: '2px' }}>
              <td style={{ fontWeight: 'bold', width: '105px' }}>Created At</td>
              <td>
                <span style={{ paddingRight: '2px', fontWeight: 'bold' }}>
                  :
                </span>
                {rowData?.createdAt ? (
                  formatDate(rowData?.createdAt)
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>
            {rowData?.updated_by &&<tr style={{ marginBottom: '2px' }}>
              <td
                style={{
                  fontWeight: 'bold',
                  width: '105px',
                  verticalAlign: 'top',
                }}
              >
                {' '}
                Updated By
              </td>
              <td
                style={{
                  maxWidth: '300px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap', lineHeight: '1.5',
                }}
              >
                 <span style={{ paddingRight: '2px', fontWeight:'bold' }}>:</span>
                {rowData?.updated_by ? (
                 adminData.employeeDetails.find(
                  (emp) => rowData?.updated_by === emp.employee_id
                )?.employee_name
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>}
            {rowData?.updated_by && rowData?.updatedAt &&<tr style={{ marginBottom: '2px' }}>
              <td style={{ fontWeight: 'bold', width: '105px' }}>Updated At</td>
              <td>
                 <span style={{ paddingRight: '2px', fontWeight:'bold' }}>:</span>
                {rowData?.updatedAt ? (
                  formatDate(rowData?.updatedAt)
                ) : (
                  <span style={{ paddingLeft: '2px' }}>-</span>
                )}
              </td>
            </tr>}
          </tbody>
        </table>
      </table>
    )
    setAllRiskDetails(details)
  }

  const onInfoIconClick = (rowIndex, tableMeta, event) => {
    const rowData =
      rowIndex !== null && (riskData || accountgenericriskData)
        ? rows[rowIndex]
        : {}
    showAllTheData(rowData)
    setAnchor(anchor ? null : event.currentTarget)
  }

  const onEdit = (index) => () => {
    setEditRowIndex(index)
    setEditForm(true)
    handleFormOpen()
    setSelectedRows([])
  }

  const fetchAccountGenericRiskdata = () => {
    const data = { accounts: uniqueAccount }
    dispatch(getAccountGenericRiskrecods(data))
  }

  const onAccountGenericChange = (event) => {
    let value = event.target.value == 'Yes' ? 'No' : 'Yes'
    setAccountGenericFilter(value)
    dispatch(
      updateFilterData({
        vertical: [],
        account: [],
        project: [],
        health_status: [],
        risk_level: [],
        risk_status: [],
      })
    )
    if (value == 'Yes') {
      fetchAccountGenericRiskdata()
    } else {
      fetchRiskdata()
    }
    setSelectedRows([])
  }
  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Risk Data')

    if (accountgenericfilter === 'Yes') {
      worksheet.getRow(1).values = [
        'Vertical-Account',
        '',
        '',
        'Risk Identification',
        '',
        '',
        'Risk Analysis',
        '',
        '',
        '',
        'Risk Response',
        '',
        '',
        '',
        '',
        '',
      ]
      worksheet.mergeCells('A1:C1')
      worksheet.mergeCells('D1:F1')
      worksheet.mergeCells('G1:J1')
      worksheet.mergeCells('K1:O1')
    } else {
      worksheet.getRow(1).values = [
        'Vertical-Account-Project-Workstream',
        '',
        '',
        '',
        '',
        'Risk Identification',
        '',
        '',
        '',
        'Risk Analysis',
        '',
        '',
        'Risk Response',
        '',
        '',
        '',
        '',
        '',
      ]
      worksheet.mergeCells('A1:E1')
      worksheet.mergeCells('F1:I1')
      worksheet.mergeCells('J1:L1')
      worksheet.mergeCells('M1:R1')
    }

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { name: 'Poppins', size: 10, bold: true }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc000' },
      }
      cell.border = {
        top: { style: 'thin', color: { argb: '262626' } },
        left: { style: 'thin', color: { argb: '262626' } },
        bottom: { style: 'thin', color: { argb: '262626' } },
        right: { style: 'thin', color: { argb: '262626' } },
      }
    })

    worksheet.getRow(2).values = [
      '#',
      'Vertical',
      'Account',
      ...(accountgenericfilter === 'Yes' ? [] : ['Project', 'Workstream']),
      'Category',
      'Risk Description',
      'Consequence',
      'Existing Controls/Measures',
      'Likelihood',
      'Impact',
      'Risk Level',
      'Risk Response',
      'Risk Response Details',
      'Risk Owner',
      'Status',
      'ETA',
      'Updated On',
    ]
    worksheet.getRow(2).eachCell((cell) => {
      cell.font = { name: 'Poppins', size: 9 }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE699' },
      }
      cell.border = {
        top: { style: 'thin', color: { argb: '262626' } },
        left: { style: 'thin', color: { argb: '262626' } },
        bottom: { style: 'thin', color: { argb: '262626' } },
        right: { style: 'thin', color: { argb: '262626' } },
      }
    })

    worksheet.columns = [
      { key: 'serial_number', width: 5 },
      { key: 'vertical', width: 20 },
      { key: 'account', width: 20 },
      ...(accountgenericfilter === 'Yes'
        ? []
        : [
            { key: 'project', width: 25 },
            { key: 'workstream', width: 25 },
          ]),
      { key: 'category', width: 15 },
      { key: 'risk_description', width: 40 },
      { key: 'consequence', width: 40 },
      { key: 'measure', width: 40 },
      { key: 'likelihood', width: 15 },
      { key: 'impact', width: 15 },
      { key: 'risk_level', width: 15 },
      { key: 'risk_response', width: 25 },
      { key: 'risk_response_details', width: 40 },
      { key: 'risk_owner', width: 20 },
      { key: 'risk_status', width: 15 },
      { key: 'eta', width: 15 },
      { key: 'updatedAt', width: 20 },
    ]

    selectedRisksData.forEach((row, i) => {
      const rowData = {
        serial_number: i + 1,
        vertical: row.vertical,
        account: row.account,
        ...(accountgenericfilter === 'Yes'
          ? {}
          : {
              project: row.project,
              workstream: row.workstream,
            }),
        category: row.category,
        risk_description: row.risk_description,
        consequence: row.consequence,
        measure: row.measure,
        likelihood: row.likelihood,
        impact: row.impact,
        risk_level: row.risk_level,
        risk_response: row.risk_response,
        risk_response_details: row.risk_response_details,
        risk_owner: row.risk_owner,
        risk_status: row.risk_status,
        eta: row.eta,
        updatedAt: row.updatedAt,
      }

      const newRow = worksheet.addRow(rowData)
      newRow.eachCell((cell) => {
        cell.font = { name: 'Poppins', size: 9 }
        cell.alignment = { vertical: 'middle', horizontal: 'left' }
      })
    })

    // Apply conditional formatting
    const applyConditionalFormatting = (cell, value) => {
      let fillColor
      switch (value?.toLowerCase()) {
        case 'critical':
          fillColor = 'E10000'
          break
        case 'high':
          fillColor = 'EDB13C'
          break
        case 'medium':
          fillColor = 'F2FB21'
          break
        case 'low':
          fillColor = '96BD49'
          break
        case 'very low':
          fillColor = '00A86B'
          break
        default:
          fillColor = 'FFFFFF'
      }

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: fillColor },
      }
      cell.font = { bold: true }
    }

    selectedRisksData.forEach((row, index) => {
      const currentRow = worksheet.getRow(index + 3)
      applyConditionalFormatting(
        currentRow?.getCell('likelihood'),
        row.likelihood
      )
      applyConditionalFormatting(currentRow?.getCell('impact'), row.impact)
      applyConditionalFormatting(
        currentRow?.getCell('risk_level'),
        row.risk_level
      )
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const fileName = props?.healthFlag ? 'Closed Risks' : 'Risks.xlsx'
    saveAs(blob, fileName)
  }

  const handleUpload = async () => {
    setUploadOpen(true)
  }

  useEffect(() => {
    getMetaData()
    dispatch(fetchRiskOptions()).unwrap()
    return () => {
      dispatch(
        updateFilterData({
          vertical: [],
          account: [],
          project: [],
          health_status: [],
          risk_level: [],
          risk_status: [],
        })
      )
    }
  }, [])

  useEffect(() => {
    setUniqueVertical(_.uniq(_.map(currentData, 'vertical'))?.sort())
    setUniqueAccount(_.uniq(_.map(currentData, 'account'))?.sort())
  }, [currentData])

  const handleReadmeOpen = () => {
    setOpenReadme(true)
  }

  const handleReadmeClose = () => {
    setOpenReadme(false)
  }

  useEffect(() => {
    dispatch(
      updateSelectedRisksData(selectedRows?.length > 0 ? selectedRows : rows)
    )
  }, [selectedRows, rows])

  const handleSelectAllRows = () => {
    if (selectedRows.length === rows.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(rows)
    }
  }

  const handleSelectRow = (rowData) => {
    const isSelected = selectedRows.includes(rowData)
    if (isSelected) {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== rowData)
      )
    } else {
      setSelectedRows([...selectedRows, rowData])
    }
  }

  const columns = useMemo(
    () => [
      {
        name: 'checkbox',
        label: '',
        options: {
          filter: false,
          sort: false,
          empty: true,
          download: false,
          customHeadLabelRender: () => {
            const isIndeterminate =
              selectedRows.length > 0 && selectedRows.length < rows.length
            return (
              <div
                style={{
                  textAlign: 'center',
                  width: '5%',
                  margin: '0px 0px 0px -5px',
                }}
              >
                <Checkbox
                  checked={
                    rows.length > 0 && selectedRows.length === rows.length
                  }
                  indeterminate={isIndeterminate}
                  onChange={handleSelectAllRows}
                  disabled={rows.length === 0}
                />
              </div>
            )
          },
          customBodyRenderLite: (dataIndex) => {
            return (
              <div
                style={{
                  textAlign: 'center',
                  width: '5%',
                  margin: '0px 1px 0px -5px',
                }}
              >
                <Checkbox
                  checked={selectedRows.includes(rows[dataIndex])}
                  onChange={() => handleSelectRow(rows[dataIndex])}
                />
              </div>
            )
          },
        },
      },
      {
        label: 'Vertical',
        name: 'vertical',
        options: {
          sort: false,
          display: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Account',
        name: 'account',
        options: {
          sort: false,
          display: true,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project - Workstream',
        name: 'project',
        options: {
          sort: false,
          display: accountgenericfilter === 'Yes' ? false : true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', margin: '0px 1px 0px -5px' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            const project = tableMeta.rowData[3]
            const workstream = tableMeta.rowData[4]
            return `${project} ${workstream ? `- ${workstream}` : ''}`
          },
        },
      },
      {
        label: 'Workstream',
        name: 'workstream',
        options: {
          sort: false,
          display: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Description',
        name: 'risk_description',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <LightTooltip title={value} arrow placement="top">
                <div
                  style={{
                    width: '8rem',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                  }}
                >
                  {value}
                </div>
              </LightTooltip>
            )
          },
        },
      },
      {
        label: 'Status',
        name: 'risk_status',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                {columnMeta.label}
              </div>
            )
          },
        },
      },
      {
        label: 'Category',
        name: 'category',
        options: {
          sort: false,
          display: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Risk Level',
        name: 'risk_level',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Risk Owner',
        name: 'risk_owner',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            const { employeeDetails } = adminData || {}
            const emailId = tableMeta.rowData[9]
              ? tableMeta.rowData[9].toLowerCase()
              : ''
            const employee = employeeDetails?.find(
              (option) => option?.email_id == emailId
            )
            const cleanedEmail = emailId
              ?.replace(/(@tredence\.com|\d+|\.)/g, ' ')
              ?.trim()
            return employee?.employee_name || cleanedEmail || ''
          },
        },
      },
      {
        label: 'Actions',
        name: 'actions',
        filter: false,
        options: {
          sort: false,
          empty: true,
          filter: false,
          download: false,
          display: !props?.healthFlag,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div style={flexStyle}>
                <LightTooltip title="Edit Risk">
                  <IconButton
                    aria-label="Edit Risk"
                    color="primary"
                    onClick={onEdit(
                      tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
                    )}
                  >
                    <Edit />
                  </IconButton>
                </LightTooltip>
                <LightTooltip title="Risk Info">
                  <IconButton
                    aria-label="Info icon"
                    color="primary"
                    onClick={(event) => {
                      const rowData =
                        tableMeta?.currentTableData?.[tableMeta?.rowIndex]
                      if (rowData) {
                        onInfoIconClick(rowData.index, tableMeta, event)
                      }
                    }}
                  >
                    <InfoOutlinedIcon style={{ fontSize: '15px' }} />
                  </IconButton>
                </LightTooltip>
                {/* window closed open for only admin */}
              </div>
            )
          },
        },
      },
    ],
    [rows, filterData, selectedRows, accountgenericfilter]
  )

  const fetchRiskdata = () => {
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    dispatch(getRiskrecods(data))
  }

  useEffect(() => {
    fetchRiskdata()
  }, [])

  const filtersData = (data) => {
    let filteredData = data
    if (filterData.vertical && filterData.vertical.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.vertical.includes(item.vertical)
      )
    }
    if (filterData.account && filterData.account.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account)
      )
    }
    if (filterData.project && filterData.project.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account) &&
          filterData.project.includes(item.project)
      )
    }
    if (filterData.vertical.length === 0 && filterData.risk_level.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.risk_level.includes(item.risk_level)
      )
    }
    if (
      filterData.vertical.length > 0 &&
      filterData.risk_level &&
      filterData.risk_level.length > 0
    ) {
      if (filterData.risk_status?.length > 0) {
        if (accountgenericfilter === 'Yes') {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.risk_level.includes(item.risk_level) &&
              filterData.risk_status.includes(item.risk_status)
          )
        } else {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.project.includes(item.project) &&
              filterData.risk_level.includes(item.risk_level) &&
              filterData.risk_status.includes(item.risk_status)
          )
        }
      } else {
        if (accountgenericfilter === 'Yes') {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.risk_level.includes(item.risk_level)
          )
        } else {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.project.includes(item.project) &&
              filterData.risk_level.includes(item.risk_level)
          )
        }
      }
    }
    if (filterData.vertical.length === 0 && filterData.risk_status.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.risk_status.includes(item.risk_status)
      )
    }
    if (
      filterData.vertical.length > 0 &&
      filterData.risk_status &&
      filterData.risk_status.length > 0
    ) {
      if (filterData.risk_level?.length > 0) {
        if (accountgenericfilter === 'Yes') {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.risk_status.includes(item.risk_status) &&
              filterData.risk_level.includes(item.risk_level)
          )
        } else {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.project.includes(item.project) &&
              filterData.risk_status.includes(item.risk_status) &&
              filterData.risk_level.includes(item.risk_level)
          )
        }
      } else {
        if (accountgenericfilter === 'Yes') {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.risk_status.includes(item.risk_status)
          )
        } else {
          filteredData = filteredData.filter(
            (item) =>
              filterData.vertical.includes(item.vertical) &&
              filterData.account.includes(item.account) &&
              filterData.project.includes(item.project) &&
              filterData.risk_status.includes(item.risk_status)
          )
        }
      }
    }
    return filteredData
  }

  useEffect(() => {
    if (
      (filterData.vertical && filterData.vertical.length > 0) ||
      (filterData.risk_level && filterData.risk_level.length > 0) ||
      (filterData.risk_status && filterData.risk_status.length > 0)
    ) {
      if (accountgenericfilter == 'Yes') {
        let filteredData = filtersData(accountgenericriskData)
        setRows(filteredData)
      } else {
        let filteredData = filtersData(riskData)
        setRows(filteredData)
      }
    } else if (
      filterData.vertical.length === 0 ||
      (filterData.risk_level && filterData.risk_level.length > 0) ||
      (filterData.risk_status && filterData.risk_status.length > 0)
    ) {
      if (accountgenericfilter == 'Yes') {
        setRows(accountgenericriskData)
      } else {
        setRows(riskData)
      }
    } else {
      if (accountgenericfilter == 'Yes') {
        setRows(accountgenericriskData)
      } else {
        setRows(riskData)
      }
    }
  }, [filterData])

  useEffect(() => {
    if (accountgenericfilter == 'Yes') {
      setRows(accountgenericriskData)
      dispatch(updateRiskInitialData(accountgenericriskData))
    } else {
      if (props?.healthFlag) {
        const data = JSON.parse(JSON.stringify(riskData))
        setRows(data?.filter((d) => d?.risk_status === 'Closed'))
        setSelectedRows([])
        dispatch(updateSelectedRisksData(rows))
      } else {
        setRows(riskData)
        dispatch(updateRiskInitialData(riskData))
      }
    }
  }, [
    riskData,
    accountgenericfilter,
    accountgenericriskData,
    props?.healthFlag,
  ])

  useEffect(() => {
    if (isLoadingRisk || isLoadingaccountgeneicRisk || loading) {
      setOpenBackdrop(true)
    } else {
      setOpenBackdrop(false)
    }
  }, [isLoadingRisk, isLoadingaccountgeneicRisk, loading])

  const editRow =
    editRowIndex !== null && (riskData || accountgenericriskData)
      ? rows[editRowIndex]
      : {}

  const closedRisks = rows?.filter(
    (data) => data?.risk_status?.toLowerCase() === 'closed'
  )?.length
  const openRisks = rows?.filter(
    (data) => data?.risk_status?.toLowerCase() === 'open'
  )?.length

  return (
    <>
      <Loading open={openBackdrop} />
      <Box>
        <Box
          display="flex"
          justifyContent={props?.healthFlag ? 'flex-end' : 'space-between'}
        >
          {!props?.healthFlag && (
            <Box display="flex" flexDirection="column">
              <h5>
                Show Account Generic :{' '}
                <Switch
                  value={accountgenericfilter}
                  checked={accountgenericfilter == 'Yes' ? true : false}
                  onChange={(event) => onAccountGenericChange(event)}
                />
              </h5>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: props?.healthFlag ? 0 : '5px'
            }}
          >
            {!props?.healthFlag && accountgenericfilter !== 'Yes' && (
              <Tooltip title={'Upload Risks'}>
                <Button
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={handleUpload}
                >
                  <CloudUploadIcon />
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={
                props?.healthFlag ? 'Download Closed Risks' : 'Download Risks'
              }
            >
              <Button
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleDownload}
              >
                <CloudDownloadIcon />
              </Button>
            </Tooltip>
            {!props?.healthFlag && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {riskInitialData.length > 0 && (
                    <>
                      <Tooltip
                        title={'Click here to filter the data'}
                        leaveTouchDelay={3000}
                        enterTouchDelay={20}
                      >
                        <FilterListIcon
                          variant="contained"
                          color="primary"
                          onClick={handleClick}
                          sx={{
                            marginRight: '18px',
                            cursor: 'pointer',
                          }}
                        />
                      </Tooltip>
                      <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                      >
                        <Box
                          sx={{
                            p: 1.2,
                            backgroundColor: '#f9f9f9',
                            maxWidth:
                              isSmallScreen || isBetweenSmAndMd
                                ? '300px'
                                : '500px',
                          }}
                        >
                          <FilterComponent
                            setAnchorEl={setAnchorEl}
                            keyName={'riskManagement'}
                            accountgeneric={accountgenericfilter}
                          />
                        </Box>
                      </Popper>
                    </>
                  )}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFormOpen}
                >
                  ADD RISK
                </Button>
              </>
            )}
          </Box>
        </Box>
        {!props?.healthFlag && (
          <Box
            display="flex"
            justifyContent='space-between'
          >
            <div
              style={{
                fontSize: '10px',
                color: 'primary',
                marginBottom: '10px',
              }}
            >
              <div>Count of open risk(s): {`${openRisks}`}</div>
              <div>Count of closed risk(s): {`${closedRisks}`}</div>
            </div>
            <Grid>
              <div>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={handleReadmeOpen}
                >
                  ReadMe File
                </Typography>
              </div>
            </Grid>
          </Box>
        )}

        <Grid>
          <RiskTable
            data={rows}
            columns={columns}
            selectable={true}
            setSelectedRows={setSelectedRows}
            accountgeneric={accountgenericfilter}
          />
        </Grid>
      </Box>
      <Popover
        open={openAllRiskData}
        onClose={handleAllRisksDataClose}
        aria-describedby="alert-dialog-slide-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: '1',
          fontSize: 12,
          borderRadius: 3,
        }}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '400px',
            maxHeight: '100%',
            margin: '15px',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          <Typography
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {allRiskDetails}
          </Typography>
        </div>
      </Popover>

      {uploadOpen && (
        <RisksBulkUpload
          openBulkUploadModel={uploadOpen}
          setOpenBulkUploadModel={setUploadOpen}
          fetchRiskdata={fetchRiskdata}
        />
      )}

      {openForm && (
        <RiskForm
          open={openForm}
          onClose={onClose}
          metadata={currentData}
          verticals={uniqueVertical}
          accounts={uniqueAccount}
          workstreams={adminData.workstreams}
          employees={adminData.employeeDetails}
          editForm={editForm}
          editRow={editRow}
          user={userDetails}
          fetchRiskdata={fetchRiskdata}
          accountgenericfilter={accountgenericfilter}
          fetchAccountGenericRiskdata={fetchAccountGenericRiskdata}
        />
      )}
      {openReadme && (
        <ReadmeFile open={openReadme} onClose={handleReadmeClose} />
      )}
    </>
  )
}

export default RiskManagement
