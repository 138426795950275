import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControl,
  Grid,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilterData } from '../../../../redux/reducer/projectHealthFilterSlice'
import { useTheme } from '@emotion/react'

const useStyles = makeStyles((theme) => ({
  ellipsisSelect: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export default function FilterComponent(props) {
  const [rows, setRows] = useState([])
  const [selectAll, setSelectAll] = useState({
    vertical: false,
    account: false,
    project: false,
    status: false,
    risk_level: false,
    risk_status: false,
  })
  const [isReset, setIsReset] = useState(false)
  const [optionValues1, setOptionValues1] = useState([])
  const [optionValues2, setOptionValues2] = useState([])
  const [optionValues3, setOptionValues3] = useState([])
  //const [optionValues4, setOptionValues4] = useState([])
  const [optionValues5, setOptionValues5] = useState([])
  const [optionValues6, setOptionValues6] = useState([])
  const [optionValues7, setOptionValues7] = useState([])
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    consolidatedViewInitialData,
    weeklyHealthInitialData,
    otherArtifactsInitialData,
    riskInitialData,
    filterData,
    exclude,
  } = useSelector((state) => state.filter)
  useEffect(() => {
    if (props.keyName === 'weeklyHealth') {
      setRows(weeklyHealthInitialData)
    } else if (props.keyName === 'consolidatedView') {
      setRows(consolidatedViewInitialData)
    } else if (props.keyName === 'otherArtifacts') {
      setRows(otherArtifactsInitialData)
    } else if (props.keyName === 'riskManagement') {
      setRows(riskInitialData)
    }
  }, [
    weeklyHealthInitialData,
    consolidatedViewInitialData,
    otherArtifactsInitialData,
    riskInitialData,
    props.keyName,
  ])

  useEffect(() => {
    if (!rows || rows.length === 0) {
      return
    }
    const array1 = rows.map((v) => v.vertical).sort()
    const selectAll = [...array1]
    setOptionValues1(_.uniq(selectAll))
    const filteredStatus = rows.map((item) => item.health_status).sort()
    setOptionValues5(_.uniq(filteredStatus))

    const filteredRiskLevel = rows.map((item) => item.risk_level).sort()
    setOptionValues6(_.uniq(filteredRiskLevel))
    const filteredRiskStatus = rows.map((item) => item.risk_status).sort()
    setOptionValues7(_.uniq(filteredRiskStatus))
  }, [rows, isReset])

  useEffect(() => {
    const filteredAccounts =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                filterData.vertical?.includes(item.vertical) &&
                exclude == item?.exclude
            )
            .map((item) => item.account)
            .sort()
        : rows
            .filter((item) => filterData.vertical?.includes(item.vertical))
            .map((item) => item.account)
            .sort()
    setOptionValues2(_.uniq(filteredAccounts))

    const filteredProjects =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                filterData.account?.includes(item.account) &&
                exclude == item?.exclude
            )
            .map((item) => item.project)
            .sort()
        : rows
            .filter((item) => filterData.account?.includes(item.account))
            .map((item) => item.project)
            .sort()
    setOptionValues3(_.uniq(filteredProjects))
    // if(filterData.exclude.length===0&& filterData.health_status.length>0){
    //   const filteredExcludes = rows.filter((item) =>filterData.account.includes(item.account)&&filterData.health_status.includes(item.health_status)).map((item) => item.exclude).sort()
    //   setOptionValues4(_.uniq(filteredExcludes))
    // }else if(filterData.exclude.length===0){
    //   const filteredExcludes = rows.filter((item) =>filterData.account.includes(item.account)).map((item) => item.exclude).sort()
    //   setOptionValues4(_.uniq(filteredExcludes))
    // }
    // if(filterData.exclude.length>0&& filterData.health_status.length===0){
    //   const filteredStatus = rows.filter((item) =>filterData.account.includes(item.account)&&filterData.exclude.includes(item.exclude)).map((item) => item.health_status).sort()
    // setOptionValues5(_.uniq(filteredStatus))
    // }else if(filterData.health_status.length===0){
    if (filterData?.project?.length > 0) {
      const filteredStatus = rows
        .filter(
          (item) =>
            filterData?.project.includes(item.project) &&
            exclude === item.exclude
        )
        .map((item) => item.health_status)
      setOptionValues5(_.uniq(filteredStatus))
    } else {
      const filteredStatus = rows
        .filter((item) => exclude === item.exclude)
        .map((item) => item.health_status)
        .sort()
      setOptionValues5(_.uniq(filteredStatus))
    }
    if (filterData?.project?.length > 0) {
      if (filterData?.risk_level?.length > 0) {
        const filteredRiskStatus = rows
          .filter(
            (item) =>
              filterData?.project.includes(item.project) &&
              filterData?.risk_level.includes(item.risk_level)
          )
          .map((item) => item.risk_status)
        setOptionValues7(_.uniq(filteredRiskStatus))
      } else {
        const filteredRiskStatus = rows
          .filter((item) => filterData?.project.includes(item.project))
          .map((item) => item.risk_status)
        setOptionValues7(_.uniq(filteredRiskStatus))
      }

      if (filterData?.risk_status?.length > 0) {
        const filteredRiskLevel = rows
          .filter(
            (item) =>
              filterData?.project.includes(item.project) &&
              filterData?.risk_status.includes(item.risk_status)
          )
          .map((item) => item.risk_level)
        setOptionValues6(_.uniq(filteredRiskLevel))
      } else {
        const filteredRiskLevel = rows
          .filter((item) => filterData?.project.includes(item.project))
          .map((item) => item.risk_level)
        setOptionValues6(_.uniq(filteredRiskLevel))
      }
    }

    if (props.keyName === 'riskManagement' && props?.accountgeneric === 'Yes') {
      if (filterData?.risk_level?.length > 0) {
        const filteredRiskStatus = rows
          .filter(
            (item) =>
              filterData?.account.includes(item.account) &&
              filterData?.risk_level.includes(item.risk_level)
          )
          .map((item) => item.risk_status)
        setOptionValues7(_.uniq(filteredRiskStatus))
      } else {
        const filteredRiskStatus = rows
          .filter((item) => filterData?.account.includes(item.account))
          .map((item) => item.risk_status)
        setOptionValues7(_.uniq(filteredRiskStatus))
      }

      if (filterData?.risk_status?.length > 0) {
        const filteredRiskLevel = rows
          .filter(
            (item) =>
              filterData?.account.includes(item.account) &&
              filterData?.risk_status.includes(item.risk_status)
          )
          .map((item) => item.risk_level)
        setOptionValues6(_.uniq(filteredRiskLevel))
      } else {
        const filteredRiskLevel = rows
          .filter((item) => filterData?.account.includes(item.account))
          .map((item) => item.risk_level)
        setOptionValues6(_.uniq(filteredRiskLevel))
      }
    }

    // const filteredStatus = rows.map((item) => item.health_status).sort()
    // setOptionValues5(_.uniq(filteredStatus))
    // }
  }, [filterData, optionValues1, exclude])

  useEffect(() => {
    setSelectAll({
      vertical: filterData?.vertical.length === optionValues1?.length,
      account: filterData?.account.length === optionValues2?.length,
      project: filterData?.project.length === optionValues3?.length,
      status: filterData?.health_status?.length === optionValues5?.length,
      risk_level: filterData?.risk_level?.length === optionValues6?.length,
      risk_status: filterData?.risk_status?.length === optionValues7?.length,
    })
  }, [
    optionValues1,
    optionValues2,
    optionValues3,
    optionValues5,
    optionValues6,
    optionValues7,
    filterData,
  ])

  const onClickHandler = () => {
    setIsReset(true)
    dispatch(
      updateFilterData({
        vertical: [],
        account: [],
        project: [],
        /* exclude:[],*/ health_status: [],
        risk_level: [],
        risk_status: [],
      })
    )
  }

  const handleSelectAllChange = (value, sourceData, fieldName) => {
    let updatedValues = []
    if (value.includes('Select All')) {
      updatedValues = selectAll[fieldName] ? [] : sourceData || []
      setSelectAll({ ...selectAll, [fieldName]: !selectAll[fieldName] })
    } else {
      updatedValues = value
      setSelectAll({ ...selectAll, [fieldName]: false })
    }
    if (sourceData.length === value.length) {
      updatedValues = value.length ? sourceData : []
      setSelectAll({ ...selectAll, [fieldName]: value.length })
    }
    return updatedValues
  }

  const onVerticalChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues1, 'vertical')
    dispatch(
      updateFilterData({
        vertical: value,
        account: [],
        project: [],
        /* exclude:[],*/ health_status: [],
        risk_level: [],
        risk_status: [],
      })
    )
    const filteredAccounts =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                value.includes(item.vertical) && exclude === item?.exclude
            )
            .map((item) => item.account)
        : rows
            .filter((item) => value.includes(item.vertical))
            .map((item) => item.account)
    setOptionValues2(_.uniq(filteredAccounts))
  }

  const onAccountChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues2, 'account')

    dispatch(
      updateFilterData({
        account: value,
        project: [],
        /* exclude:[],*/ health_status: [],
        risk_level: [],
        risk_status: [],
      })
    )
    const filteredProjects =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                value.includes(item.account) && exclude === item?.exclude
            )
            .map((item) => item.project)
        : rows
            .filter((item) => value.includes(item.account))
            .map((item) => item.project)
    setOptionValues3(_.uniq(filteredProjects))
    // const filteredExcludes = rows.filter((item) => value.includes(item.account)).map((item) => item.exclude)
    // const filteredStatus = rows.filter((item) => value.includes(item.account)).map((item) => item.health_status)
    // setOptionValues4(_.uniq(filteredExcludes))
    // setOptionValues5(_.uniq(filteredStatus))
    // } else {
    //   dispatch(
    //     updateFilterData({
    //       account: [],
    //       project: [],
    //       /* exclude:[],*/ health_status: [],
    //     })
    //   )
    // }
  }
  const onProjectChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues3, 'project')
    dispatch(updateFilterData({ project: value }))
    const filteredStatus =
      props.keyName === 'weeklyHealth'
        ? rows
            .filter(
              (item) =>
                value.includes(item.project) && exclude === item?.exclude
            )
            .map((item) => item.health_status)
        : rows
            .filter((item) => value.includes(item.project))
            .map((item) => item.health_status)
    setOptionValues5(_.uniq(filteredStatus))
  }

  const onStatusChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues5, 'status')
    dispatch(updateFilterData({ health_status: value }))
  }

  const onRiskLevelChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues6, 'risk_level')
    dispatch(updateFilterData({ risk_level: value }))
  }
  const onRiskStatusChange = async (event) => {
    setSelectAll(false)
    let data = event.target.value
    let value = await handleSelectAllChange(data, optionValues7, 'risk_status')
    dispatch(updateFilterData({ risk_status: value }))
  }

  const clickAwayHandler = (event) => {
    if (event.target.localName === 'body') {
      return
    }
    props.setAnchorEl(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={(e) => clickAwayHandler(e)}>
        <Box
          sx={{ flexFlow: 'row wrap',margin:'10px' }}
          display="flex"
          gap="10px"
          maxWidth="245px"
        >
          <Grid container alignItems="center">
            <Grid style={{ marginLeft: '5px' }} item>
              <Typography style={{ fontSize: '14px' }} variant="body1">
                Filter
              </Typography>
            </Grid>
            <Grid style={{ marginLeft: '35px' }} item>
              <Button
                onClick={onClickHandler}
                sx={{ textTransform: 'none', fontSize: '14px' }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>

          <FormControl
            variant="standard"
            sx={{ m: 1, width: isSmallScreen || isBetweenSmAndMd ? 200 : 100 }}
            size="small"
          >
            <InputLabel>Vertical</InputLabel>
            <Select
              sx={{ height: '50%' }}
              value={filterData.vertical}
              label="vertical"
              className={classes.ellipsisSelect}
              onChange={(event) => onVerticalChange(event)}
              multiple
              renderValue={(selected) => selected.join(', ')}
            >
              {optionValues1.length > 0 ? (
                <MenuItem key="SelectAll" value="Select All">
                  <Checkbox checked={selectAll['vertical']} />
                  <ListItemText primary="Select All" />
                </MenuItem>
              ) : (
                ''
              )}
              {optionValues1?.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={filterData?.vertical?.includes(item)} />{' '}
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip
            title={
              filterData.vertical.length === 0
                ? 'Please select the Vertical'
                : ''
            }
            leaveTouchDelay={3000}
            enterTouchDelay={20}
          >
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                width: isSmallScreen || isBetweenSmAndMd ? 200 : 100,
              }}
              size="small"
            >
              <InputLabel>Account</InputLabel>
              <Select
                sx={{ height: '50%' }}
                disabled={filterData.vertical.length === 0}
                value={filterData.account}
                label="account"
                className={classes.ellipsisSelect}
                onChange={(event) => {
                  onAccountChange(event)
                }}
                multiple // Enable multiple selection
                renderValue={(selected) => selected.join(', ')} // Display selected values
              >
                {optionValues2.length > 0 ? (
                  <MenuItem key="SelectAll" value="Select All">
                    <Checkbox checked={selectAll['account']} />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                ) : (
                  ''
                )}
                {optionValues2?.map((item) => (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={filterData?.account?.includes(item)} />{' '}
                    <ListItemText primary={item} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
          {!(
            props.keyName === 'riskManagement' &&
            props?.accountgeneric === 'Yes'
          ) && (
            <Tooltip
              title={
                filterData.account.length === 0 &&
                filterData.vertical.length === 0
                  ? 'Please select the Vertical & Account'
                  : filterData.account.length === 0 &&
                    filterData.vertical.length !== 0
                  ? 'Please select the Account'
                  : ''
              }
              leaveTouchDelay={3000}
              enterTouchDelay={20}
            >
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  width: isSmallScreen || isBetweenSmAndMd ? 200 : 100,
                }}
                size="small"
              >
                <InputLabel>Project</InputLabel>
                <Tooltip leaveTouchDelay={3000} enterTouchDelay={20}>
                  <Select
                    sx={{ height: '50%' }}
                    disabled={
                      filterData.account.length === 0 ||
                      (props.keyName === 'riskManagement' &&
                        props?.accountgeneric === 'Yes')
                    }
                    value={filterData?.project}
                    label="project"
                    className={classes.ellipsisSelect}
                    onChange={(event) => onProjectChange(event)}
                    multiple
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {optionValues3.length > 0 ? (
                      <MenuItem key="SelectAll" value="Select All">
                        <Checkbox checked={selectAll['project']} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    ) : (
                      ''
                    )}
                    {optionValues3?.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={filterData?.project?.includes(item)}
                        />{' '}
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </FormControl>
            </Tooltip>
          )}
          {props.keyName === 'weeklyHealth' && (
            <Tooltip
              leaveTouchDelay={3000}
              enterTouchDelay={20}
              title={
                filterData.vertical.length === 0
                  ? ''
                  : filterData.account.length === 0
                  ? 'Please select the Account'
                  : filterData.account.length === 0 &&
                    filterData.vertical.length !== 0
                  ? 'Please select the Account'
                  : filterData.project.length === 0
                  ? 'Please select the Project'
                  : ''
              }
            >
              {exclude === 'No' && (
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    width: isSmallScreen || isBetweenSmAndMd ? 200 : 100,
                  }}
                  size="small"
                >
                  <InputLabel>Overall Health Status</InputLabel>
                  <Tooltip title={''}>
                    <Select
                      sx={{ height: '50%' }}
                      disabled={
                        filterData.vertical.length > 0 &&
                        filterData.project.length === 0
                      }
                      value={filterData?.health_status}
                      //onClick={filterData.vertical.length===0?()=>{dispatch(updateFilterData({exclude:[]}))}:()=>{}}
                      label="project"
                      className={classes.ellipsisSelect}
                      onChange={(event) => onStatusChange(event)}
                      multiple
                      renderValue={(selected) => selected?.join(', ')}
                    >
                      {optionValues5.length > 0 ? (
                        <MenuItem key="SelectAll" value="Select All">
                          <Checkbox checked={selectAll['status']} />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      ) : (
                        ''
                      )}
                      {optionValues5?.map((item) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox
                            checked={filterData?.health_status?.includes(item)}
                          />{' '}
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>
              )}
            </Tooltip>
          )}
          {props.keyName === 'riskManagement' && (
            <Tooltip
              leaveTouchDelay={3000}
              enterTouchDelay={20}
              title={
                filterData.vertical.length === 0
                  ? ''
                  : filterData.account.length === 0
                  ? 'Please select the Account'
                  : filterData.account.length === 0 &&
                    filterData.vertical.length !== 0
                  ? 'Please select the Account'
                  : props?.accountgeneric === 'Yes'
                  ? ''
                  : filterData.project.length === 0
                  ? 'Please select the Project'
                  : ''
              }
            >
              {exclude === 'No' && (
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    width: isSmallScreen || isBetweenSmAndMd ? 200 : 100,
                  }}
                  size="small"
                >
                  <InputLabel>Risk Level</InputLabel>
                  <Tooltip title={''}>
                    <Select
                      sx={{ height: '50%' }}
                      disabled={
                        props?.accountgeneric !== 'Yes'
                          ? filterData.vertical.length > 0 &&
                            filterData.project.length === 0
                          : filterData.account.length === 0
                      }
                      value={filterData?.risk_level}
                      //onClick={filterData.vertical.length===0?()=>{dispatch(updateFilterData({exclude:[]}))}:()=>{}}
                      label="project"
                      className={classes.ellipsisSelect}
                      onChange={(event) => onRiskLevelChange(event)}
                      multiple
                      renderValue={(selected) => selected?.join(', ')}
                    >
                      {optionValues6.length > 0 ? (
                        <MenuItem key="SelectAll" value="Select All">
                          <Checkbox checked={selectAll['risk_level']} />
                          <ListItemText primary="Select All" />
                        </MenuItem>
                      ) : (
                        ''
                      )}
                      {optionValues6?.map((item) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox
                            checked={filterData?.risk_level?.includes(item)}
                          />{' '}
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>
              )}
            </Tooltip>
          )}
          {props.keyName === 'riskManagement' && (
            <Tooltip
              leaveTouchDelay={3000}
              enterTouchDelay={20}
              title={
                filterData.vertical.length === 0
                  ? ''
                  : filterData.account.length === 0
                  ? 'Please select the Account'
                  : filterData.account.length === 0 &&
                    filterData.vertical.length !== 0
                  ? 'Please select the Account'
                  : props?.accountgeneric === 'Yes'
                  ? ''
                  : filterData.project.length === 0
                  ? 'Please select the Project'
                  : ''
              }
            >
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  width: isSmallScreen || isBetweenSmAndMd ? 200 : 100,
                }}
                size="small"
              >
                <InputLabel>Risk Status</InputLabel>
                <Tooltip title={''}>
                  <Select
                    sx={{ height: '50%' }}
                    disabled={
                      props?.accountgeneric !== 'Yes'
                        ? filterData.vertical.length > 0 &&
                          filterData.project.length === 0
                        : filterData.account.length === 0
                    }
                    value={filterData?.risk_status}
                    //onClick={filterData.vertical.length===0?()=>{dispatch(updateFilterData({exclude:[]}))}:()=>{}}
                    label="project"
                    className={classes.ellipsisSelect}
                    onChange={(event) => onRiskStatusChange(event)}
                    multiple
                    renderValue={(selected) => selected?.join(', ')}
                  >
                    {optionValues7.length > 0 ? (
                      <MenuItem key="SelectAll" value="Select All">
                        <Checkbox checked={selectAll['risk_status']} />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                    ) : (
                      ''
                    )}
                    {optionValues7?.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={filterData?.risk_status?.includes(item)}
                        />{' '}
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </FormControl>
            </Tooltip>
          )}
        </Box>
      </ClickAwayListener>
    </>
  )
}
