import MUIDataTable from 'mui-datatables'
import React, { useEffect, useState } from 'react'

const RiskTable = (props) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    setRows(props?.data)
  }, [props?.data])

  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    selectToolbarPlacement: 'none',
  }

  return <MUIDataTable data={rows} columns={props?.columns} options={options} />
}

export default RiskTable
