import { Warning } from '@mui/icons-material'
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom'
import PRALogo from '../../Assets/Images/PRALogo.png'
import Userlogo from '../../Assets/Images/userlogo.svg'
import { updateUserRole } from '../../redux/reducer/userSlice'
import { IconButton, useMediaQuery, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import ReportsListModel from './reportsList'

const Header = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar: notify } = useSnackbar()
  const { state } = useSelector((state) => ({ state: state }))
  const {
    submitButtonhiddenReviewid,
    submitButtonhidden,
    eltSubmitbuttonhide,
    eltSubmitbuttonhideReviewid,
  } = useSelector((state) => state.compliance)
  const lastLogin = state.user.userDetails.last_login_time
  const options = ['Reviewer', 'Reviewee']
  const currentUserRole = localStorage.getItem('project_review_employee_role')

  const [selectedIndex, setSelectedIndex] = React.useState(
    localStorage.getItem('project_review_employee_role') === 'Reviewer' ? 0 : 1
  )
  const { userDetails } = useSelector((state) => state.user)
  const [tabs, setTabs] = React.useState([
    'Project Health',
    'CSAT Nominations',
    'PULSE Dashboard',
    'Risk Management',
  ])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const [selectedTabIndex, setSelectedTabIndex] = React.useState()
  const location = useLocation()
  const [open, setOpen] = React.useState(false)
  const [openDownload, setOpenDownload] = useState(false)
  const anchorRef = React.useRef(null)

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const handleDownloadOpen = () => {
    setOpenDownload(true)
  }

  const handleCloseDownload = () => {
    setOpenDownload(false)
  }

  const handleCloseUserPopup = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  React.useEffect(() => {
    const path = location.pathname
    if (localStorage.getItem('project_review_employee_role') === 'Admin') {
      setTabs([
        'Project Health',
        'CSAT Nominations',
        'PULSE Dashboard',
        'Risk Management',
        'Employee Update',
      ])
      if (path == '/adminEmpUpdate') {
        setSelectedTabIndex(4)
      } else if (path == '/risk') {
        setSelectedTabIndex(3)
      } else if (path == '/report') {
        setSelectedTabIndex(2)
      } else if (path == '/csat') {
        setSelectedTabIndex(1)
      } else {
        setSelectedTabIndex(0)
      }
    } else {
      if (path == '/csat') {
        setSelectedTabIndex(1)
      } else if (path == '/report') {
        setSelectedTabIndex(2)
      } else if (path == '/risk') {
        setSelectedTabIndex(3)
      } else {
        setSelectedTabIndex(0)
      }
    }
  }, [userDetails.role, location])

  const handleClickListItem = (event) => {
    if (submitButtonhidden === true && eltSubmitbuttonhide === true) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMenuItemClick = (event, index) => {
    if (submitButtonhidden === true && eltSubmitbuttonhide === true) {
      setSelectedIndex(index)
      setAnchorEl(null)
      changeRole(index)
    }
  }

  const handleClose = () => {
    if (submitButtonhidden === true && eltSubmitbuttonhide === true) {
      setAnchorEl(null)
    }
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleChange = (e, index) => {
    setSelectedTabIndex(index)
    handleMenuClose()
    if (index == 0) {
      navigate('/')
    } else if (index == 1) {
      navigate('/csat')
    } else if (index == 2) {
      navigate('/report')
    }   else if (index == 3) {
      navigate('/risk')
    }else if (index == 4) 
      navigate('/adminEmpUpdate')
    
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const changeRole = (index) => {
    if (options[index] === 'Reviewer') {
      localStorage.setItem('project_review_employee_role', 'Reviewer')
      dispatch(updateUserRole('Reviewer'))
    } else {
      localStorage.setItem('project_review_employee_role', 'Reviewee')
      dispatch(updateUserRole('Reviewee'))
    }
    props.changeRole(options[index])
    navigate('/')
  }
  const verticalIds = userDetails.vertical_ids?.verticalIds
  const nameComponents = userDetails?.email_id?.replace(
    /(@tredence\.com|\d+|\.)/g,
    ' '
  )
  const initials = nameComponents
    ?.split(' ')
    .map((n) => n[0]?.toUpperCase())
    .join('')
  return (
    <>
      <div className="topbar">
        <div className="topnav">
          {/* <div
            className="role"
            style={{
              display:
                localStorage.getItem('role_switch') === 'true'
                  ? 'inline-block'
                  : 'none',
              cursor: 'pointer',
            }}
          >
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              aria-haspopup="true"
              aria-controls="lock-menu"
              onClick={handleClickListItem}
            >
              ROLE : {options[selectedIndex]}
              <ExpandMoreIcon />
            </p>
          </div> */}
          {/* <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            keepMounted
            open={
              submitButtonhidden === true && eltSubmitbuttonhide === true
                ? Boolean(anchorEl)
                : false
            }
            onClose={handleClose}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                value={options[selectedIndex]}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu> */}

          {/* <div>
            {submitButtonhidden === true && eltSubmitbuttonhide === true ? (
              <img
                src={Logout}
                className="logout"
                onClick={props.handleLogout}
                alt="img"
              />
            ) : (
              <img
                src={Logout}
                className="logout"
                onClick={() => {
                  if (submitButtonhidden === false) {
                    notify(
                      `Please submit the review with id ${submitButtonhiddenReviewid} to proceed further`,
                      Warning
                    )
                  }
                  if (eltSubmitbuttonhide === false) {
                    notify(
                      `Please submit the review with id ${eltSubmitbuttonhideReviewid} to proceed further`,
                      Warning
                    )
                  }
                }}
                alt="img"
              />
            )}
          </div> */}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            float: 'right',
            marginTop: '0.8rem',
            marginRight: '1.8rem',
          }}
        >
          <div
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            //style={{ padding:'0.1rem',color:'#373B5C',borderRadius:0,border:'1px solid #EBEBEB',fontWeight:'500',width:'12.8rem'}}padding:'0.2rem 0.5rem'padding: '8px 12px',
            style={{
              borderRadius: '50%',
              color: '#373B5C',
              border: '1px solid #373B5C',
              fontWeight: '700',
              fontSize: '1rem',
              minWidth: '2.5rem',
              height: '2.5rem',
              textTransform: 'none',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            // endIcon={<KeyboardArrowDown />}
          >
            <Tooltip title={userDetails?.email_id}>
              <div className="user">
                <div>{initials}</div>
              </div>
            </Tooltip>
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            variant="contained"
            style={{ borderRadius: 0, minWidth: '12.8rem' }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseUserPopup}>
                    <MenuList
                      autoFocusItem={open}
                      id="lock-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleCloseUserPopup}>
                        <img src={Userlogo} className="userlogo" alt="img" />
                        <span
                          style={{
                            maxWidth: '11rem',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                          }}
                        >
                          <Tooltip
                            title={localStorage.getItem(
                              'project_review_employee_name'
                            )}
                            leaveTouchDelay={3000}
                            enterTouchDelay={20}
                          >
                            <h5
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '9rem',
                              }}
                            >
                              {localStorage.getItem(
                                'project_review_employee_name'
                              )}
                            </h5>
                          </Tooltip>
                          <h6>
                            {localStorage.getItem(
                              'project_review_employee_designation'
                            ) == 'null' ||
                            localStorage
                              .getItem('project_review_employee_designation')
                              ?.trim()?.length == 0 ? (
                              currentUserRole
                            ) : (
                              <>
                                {localStorage.getItem(
                                  'project_review_employee_designation'
                                )}
                                {/* , {currentUserRole} */}
                              </>
                            )}
                          </h6>
                        </span>
                      </MenuItem>
                      <MenuItem onClick={handleCloseUserPopup}>
                        {' '}
                        <span style={{ fontSize: '0.625rem', opacity: 0.5 }}>
                          Last Logged in :{' '}
                          {lastLogin == null ? 'New User' : lastLogin}
                        </span>
                      </MenuItem>
                      {
                        <MenuItem onClick={handleCloseUserPopup}>
                          <span
                            style={{
                              fontSize: '0.75rem',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '0.625rem',
                            }}
                            onClick={handleDownloadOpen}
                          >
                            <SystemUpdateAltIcon style={{ color: '#FE5205' }} />
                            Download Reports
                          </span>
                        </MenuItem>
                      }
                      <MenuItem onClick={handleCloseUserPopup}>
                        <span
                          style={{
                            fontSize: '0.75rem',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.625rem',
                          }}
                          onClick={props.handleLogout}
                        >
                          <LogoutRoundedIcon style={{ color: '#FE5205' }} />
                          Logout
                        </span>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <div className="logo">
          {submitButtonhidden === true && eltSubmitbuttonhide === true ? (
            <Link to="/">
              <img src={PRALogo} className="logoImg" alt="img" />
              <div className="title">
                Project Review
                {/* <pre className="subTitle fontweightChange">the Value Score</pre> */}
                <pre className="fontweightChange">the Value Score</pre>
              </div>
            </Link>
          ) : (
            <Link
              onClick={() => {
                if (submitButtonhidden === false) {
                  notify(
                    `Please submit the review with id ${submitButtonhiddenReviewid} to proceed further`,
                    Warning
                  )
                }
                if (eltSubmitbuttonhide === false) {
                  notify(
                    `Please submit the review with id ${eltSubmitbuttonhideReviewid} to proceed further`,
                    Warning
                  )
                }
              }}
            >
              <img src={PRALogo} className="logo" alt="img" />
              <div className="title">
                Project Review
                {/* <pre className="subTitle fontweightChange">the Value Score</pre> */}
                <pre className="fontweightChange">the Value Score</pre>
              </div>
            </Link>
          )}
        </div>
        <div>
          {isSmallScreen || isBetweenSmAndMd ? (
            <div className="navmenu">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {tabs.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedTabIndex}
                    value={options[selectedTabIndex]}
                    onClick={(event) => handleChange(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : (
            <nav className="navbar">
              <ul className="navtabs">
                <li>
                  <NavLink
                    to="project-health"
                    className={(navData) =>
                      navData.isActive ? 'navbar__link--active' : 'none'
                    }
                  >
                    Project Health
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="csat"
                    className={(navData) =>
                      navData.isActive ? 'navbar__link--active' : 'none'
                    }
                  >
                    CSAT Nominations
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="report"
                    className={(navData) =>
                      navData.isActive ? 'navbar__link--active' : 'none'
                    }
                  >
                    PULSE
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="risk"
                    className={(navData) =>
                      navData.isActive ? 'navbar__link--active' : 'none'
                    }
                  >
                    Risk Management
                  </NavLink>
                </li>
                {localStorage.getItem('project_review_employee_role') ===
                'Admin' ? (
                  <li>
                    <NavLink
                      to="adminEmpUpdate"
                      className={(navData) =>
                        navData.isActive ? 'navbar__link--active' : 'none'
                      }
                    >
                      Employee Update
                    </NavLink>
                  </li>
                ) : (
                  ''
                )}
              </ul>
            </nav>
          )}
        </div>
        {(isSmallScreen || isBetweenSmAndMd) && (
          <span className="tabtitle">{tabs[selectedTabIndex]}</span>
        )}
        {openDownload && (
          <ReportsListModel
            open={openDownload}
            handleClose={handleCloseDownload}
          />
        )}
      </div>
    </>
  )
}

export default Header
