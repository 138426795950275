import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Papa from 'papaparse'
import ExcelJS from 'exceljs'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../Common/Loading'
import { useSnackbar } from '../../../Common/Libraries/Notify'
import * as XLSX from 'xlsx'
import ExcelGenerator from './RisksExecelTemplate'
import { getAllMetaData } from '../../../services/csatService'
import API from '../../../Common/api'

const RisksBulkUpload = (props) => {
  const [isDisabled, setisDisabled] = useState(true)
  const [isValidData, setIsValidData] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [message, setMessage] = useState('')
  const { enqueueSnackbar: notify } = useSnackbar()
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const { data: adminData } = useSelector((state) => state.admin)
  const { userDetails } = useSelector((state) => state.user)
  const [uploadTemplateData, setUploadTemplateData] = useState([])
  const uniqueEmployees = adminData.employeeDetails.filter(
    (item, index, self) =>
      index === self.findIndex((obj) => obj.email_id === item.email_id)
  )
  const [reqDta, setReqDta] = useState([])
  const fileInputRef = useRef(null);
  const columnNames = [
    'Vertical*',
    'Account*',
    'Project*',
    'Workstream',
    'Category*',
    'Risk Description*',
    'Consequence*',
    'Existing Controls/Measures',
    'Likelihood*',
    'Impact*',
    'Risk Response',
    'Risk Response Details',
    'Risk Owner*',
    'Status*',
    'ETA*',
    'Unique_Id*',
  ]
  const [deletedHeaderValues, setDeletedHeaderValues] = useState([])
  const {
    data: riskOptionData,
  } = useSelector((state) => state.risk)
  const riskResponseOptions = riskOptionData.risk_response_options.length>0? riskOptionData.risk_response_options:[]
  const riskStatusOptions = riskOptionData.risk_status_options.length>0? riskOptionData.risk_status_options:[]

  const handleInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setisDisabled(true)
    }
  };
  const employeeEmails = adminData.employeeDetails.map((item) => {
    return item.email_id
  })

  const handleFileUpload = async (idsAddedData) => {
    if (isValidData && reqDta.length>0) {
      setOpenBackdrop(true)
      await API.post(`risk/saveBulkRisksData`, {
        reqDta,
      })
        .then((response) => {
          setOpenBackdrop(false)
          notify('Data uploaded successfully', {
            variant: 'success',
          })
          setReqDta([])
          setisDisabled(true)
          props.setOpenBulkUploadModel(false)
          props.fetchRiskdata()
        })
        .catch(() => {
          notify('error in saving bulk reviews!', {
            variant: 'error',
          })
          setisDisabled(true)
          setOpenBackdrop(false)
        })
    }
  }

  const closeBulkUploadModel = () => {
    props.setOpenBulkUploadModel(false)
    setisDisabled(true)
  }

  const getMetaData = () => {
    setOpenBackdrop(true)
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    getAllMetaData(data)
      .then((res) => {
        if (res.success) {
          setUploadTemplateData(res.data.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }
  const renderErrorMessage = (errorMessage) => {
    return (
      <>
        {errorMessage.length > 0 && (
          <div className="error-messages">
            {errorMessage.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        )}
      </>
    )
  }
  useEffect(() => {
    getMetaData()
  }, [])
  const closeAlert = () => {
    setOpenAlert(false)
  }


  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }
 

  const setMessageContent = (
    missingFields,
    dateIncorrectFields,
    messageContent,
    fieldNames
  ) => {
    if (missingFields.length > 0) {
      return (
        <div key="missingFields">
          <h4>{messageContent}</h4>
          <ul>
            {missingFields.map((rowInfo) => (
              <li key={rowInfo.rowNumber}>
                <h5>Row {rowInfo.rowNumber}:</h5>{' '}
                {convertArrayToSentenceCase(rowInfo.missingFields).join(', ')}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    return null
  }

  const setDeletedColumnContent = (missingFields, messageContent) => {
    if (missingFields.length > 0) {
      return (
        <div key="missingFields">
          <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
            {messageContent}
          </div>
          <ul style={{ marginLeft:'16px' }}>
            {missingFields.map((rowInfo, index) => (
              <li style={{ fontWeight: 'bold', fontSize: '11px', }} key={index}>
                {rowInfo}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    return null
  }
 const mandatoryColumns = [
      'Category',
      'Risk Description',
      'Consequence',
      'Likelihood',
      'Impact',
      'Risk Owner',
      'ETA',
    ]

  const getColumnValues = async (event, callback) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    if (fileExtension === 'xlsx') {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target.result;
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);
        const worksheet = workbook.worksheets[0];
        const headerValues = worksheet
          .getRow(1)
          .values.filter((value) => value !== null)
          .map((value) => {
            if (typeof value === 'object' && value.richText) {
              return value.richText.map((item) => item.text).join('');
            } else {
              return value;
            }
          });
        callback(headerValues);
      };
      reader.readAsArrayBuffer(file);
    } else if (fileExtension === 'csv') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            const headerValues = results.meta.fields;
            callback(headerValues);
          },
        });
      };
      reader.readAsText(file);
    } else {
      setOpenAlert(true)
      setMessage('Invalid file.Please check the file format.')
      event.target.value = ''
      console.error('Unsupported file type:', fileExtension);
    }
  };
  
  const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase().replace(/_/g, ' ');
  };

  const convertArrayToSentenceCase = (arr) => {
    if (!Array.isArray(arr)) return arr; 
    return arr.map(toSentenceCase); 
  }

  const setInvalidMessageContent = (invalidData, keyName) => {
    return invalidData.map((v) => {
      if (keyName === 'metaData') {
        const { vertical, account, project, workstream } = v.metaData;
        const { vertical: itemVertical, account: itemAccount, project: itemProject, workstream: itemWorkStream } = v.item;
        let message = [];
        let actualValues = [];
  
        if (itemVertical !== vertical) {
          message.push(
            <span key="vertical">
              <strong>Vertical</strong>: {itemVertical}
            </span>
          );
          actualValues.push(
            <span key="actualVertical">
              <strong>Vertical</strong>: <span style={{color:'green'}}>{vertical}</span>
            </span>
          );
        }
  
        if (itemAccount !== account) {
          if (message.length > 0) {
            message.push(', ');
            actualValues.push(', ');
          }
          message.push(
            <span key="account">
              <strong>Account</strong>: {itemAccount}
            </span>
          );
          actualValues.push(
            <span key="actualAccount">
              <strong>Account</strong>:<span style={{color:'green'}}>{account}</span> 
            </span>
          );
        }
  
        if (itemProject !== project) {
          if (message.length > 0) {
            message.push(', ');
            actualValues.push(', ');
          }
          message.push(
            <span key="project">
              <strong>Project</strong>: {itemProject}
            </span>
          );
          actualValues.push(
            <span key="actualProject">
              <strong>Project</strong>: <span style={{color:'green'}}>{project}</span>
            </span>
          );
        }
  
        if (itemWorkStream && workstream && itemWorkStream !== workstream) {
          if (message.length > 0) {
            message.push(', ');
            actualValues.push(', ');
          }
          message.push(
            <span key="workstream">
              <strong>Workstream</strong>: {itemWorkStream}
            </span>
          );
          actualValues.push(
            <span key="actualWorkstream">
              <strong>Workstream</strong>: <span style={{color:'green'}}>{workstream}</span>
            </span>
          );
        }
  
        return (
          <>
            <div style={{ fontSize:'11px', marginLeft: '9px' }}>Row: {v.rowNumber}</div>

            {message.length > 0 && (
              <div style={{ marginLeft: '10px', fontSize: '11px' }}>
                 <div style={{marginLeft:'5px',fontSize:'11px'}}>{message}</div>
              </div>
            )}
            {actualValues.length > 0 && (
              <div style={{ marginLeft: '10px', fontSize: '11px',  }}>
              
                <div style={{fontWeight:'bold', marginLeft:'5px',fontSize:'10px'}}> Please find correct Data:</div> <div style={{marginLeft:'5px',fontSize:'10px'}}> {actualValues}</div>

              </div>
            )}
          </>
        );
      }
  
      if (keyName === 'riskOwnerEmail') {
        return (
          <p key={v.rowNumber}>
            Row {v.rowNumber} - {v.riskOwnerEmail}
          </p>
        );
      }
      if (keyName === 'inValidOwner') {
        return (
          <p key={v.rowNumber}>
            Row {v.rowNumber} - {v.inValidOwner}
          </p>
        );
      }
  
      if (keyName === 'inValidImpact') {
        return (
          <p key={v.rowNumber}>
            Row {v.rowNumber} -{v.inValidImpact}
          </p>
        );
      }
  
      if (keyName === 'inValidLikelihood') {
        return (
          <p key={v.rowNumber}>
            Row {v.rowNumber} - {v.inValidLikelihood}
          </p>
        );
      }
  
      if (keyName === 'invalidRiskResponses') {
        return (
          <p key={v.rowNumber}>
            Row {v.rowNumber} - {v.invalidRiskResponse}
          </p>
        );
      }
  
      if (keyName === 'invalidStatuses') {
        return (
          <p key={v.rowNumber}>
            Row {v.rowNumber} - {v.invalidStatus}
          </p>
        );
      }

      if (keyName === 'invalidEtaDate') {
        return (
          <p  key={v.rowNumber}>
            Row {v.rowNumber} - {v.invalidEtaDate}
          </p>
        );
      }

      
      if (keyName === 'invalidWorkStreams') {
        return (
          <p  style={{ fontSize: '11px', marginLeft: '8px'}} key={v.rowNumber}>
            Row {v.rowNumber} - {v.workstream}
          </p>
        );
      }
  
      return '';
    });
  };
  const convertDateFormat = (dateString) => {
    const [month, day, year] = dateString.split('/').map(Number);
    const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    return formattedDate;
  };

  function excelSerialToDate(serial, format = 'dd/mm/yyyy') {
    const excelStartDate = new Date(1900, 0, 1); 
    const date = new Date(excelStartDate.getTime() + (serial - 2) * 24 * 60 * 60 * 1000); 
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${month}/${day}/${year}`; 
}

  const isExcelSerial = (value) => {
    return typeof value === 'number' && value > 59; 
};
  const checkDateFormat = (dateString) => {
    const dateFormatRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;

    if (!dateFormatRegex.test(dateString)) {
      return false;
    }
  
    const [month, day, year] = dateString.split('/').map(Number);
  
    if (month === 2) {
      if (day === 29) {
        return isLeapYear(year);
      } else if (day > 29) {
        return false;
      }
    }
  
    if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
      return false;
    }
  
    if (day > 31) {
      return false;
    }
  
    return true;
  };
  
  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };
  
  const riskLevels = ['Critical', 'High', 'Medium', 'Low', 'Very Low'];
  const riskLevelMatrix = [
      [riskLevels[0], riskLevels[1], riskLevels[2]], 
      [riskLevels[1], riskLevels[2], riskLevels[3]], 
      [riskLevels[2], riskLevels[3], riskLevels[4]], 
  ];
  const getIndex = (array, value) => {
    return array.indexOf(value);
};

  const determineRiskLevel = (likelihood, impact) => {
      const indexLikelihood = getIndex(['High', 'Medium', 'Low'], likelihood);
      const indexImpact = getIndex(['High', 'Medium', 'Low'], impact);
  
      if (indexLikelihood === -1) {
          return null;
      }
      if (indexImpact === -1) {
          return null;
      }
  
      return riskLevelMatrix[indexLikelihood][indexImpact];
  };
 
  const setFileName = async (result, e) => {
    setReqDta([]);
    try {
      const invalidMetaData = [];
      const invalidEmails = [];
      const inValidImpact = [];
      const inValidLikelihood = [];
      const invalidWorkStreams = [];
      const invalidRiskResponses = [];  
      const invalidStatuses = [];  
      const invalidEta=[];     
      const  inValidMatchedOwner=[]
  
      const processedData = await Promise.all(
        result.map(async (item) => {
          const matchedItem = uploadTemplateData.find((metaData) => item.uniqueId === metaData.unique_id);
  
          if (matchedItem) {
            // Validate email
            if (!validateEmail(item.risk_Owner)) {
              invalidEmails.push({
                riskOwnerEmail: item.risk_Owner,
                rowNumber: item.rowIndex,
              });
            }else if (
              !employeeEmails.includes(item.risk_Owner.toLowerCase())
            ) {
              inValidMatchedOwner.push({
                inValidOwner: item.risk_Owner,
                rowNumber: item.rowIndex,
              })
            }
            let etaDate = (isExcelSerial(item.eta) ? excelSerialToDate(item.eta, 'dd/mm/yyyy') : item.eta)
            // if (!isNaN(etaDate)) {
            //   etaDate = excelDateToJSDate(Number(etaDate));
            // }
          
            if (!checkDateFormat(etaDate)) {
              invalidEta.push({
                invalidEtaDate: etaDate,
                rowNumber: item.rowIndex,
              });
            }
  
            // Validate impact
            if (!['Medium', 'Low', 'High'].includes(item.impact)) {
              inValidImpact.push({
                inValidImpact: item.impact,
                rowNumber: item.rowIndex,
              });
            }
  
            if (!['Medium', 'Low', 'High'].includes(item.likelihood)) {
              inValidLikelihood.push({
                inValidLikelihood: item.likelihood,
                rowNumber: item.rowIndex,
              });
            }
  
            if (item.risk_response && !riskResponseOptions.includes(item.risk_response)) {
              invalidRiskResponses.push({
                invalidRiskResponse: item.risk_response,
                rowNumber: item.rowIndex,
              });
            }
  
            if (!riskStatusOptions.includes(item.status)) {
              invalidStatuses.push({
                invalidStatus: item.status,
                rowNumber: item.rowIndex,
              });
            }
           
            if (
              matchedItem.vertical !== item.vertical ||
              matchedItem.account !== item.account ||
              matchedItem.project !== item.project ||
              (matchedItem.workstream && matchedItem.workstream !== item.workstream)
            ) {
              invalidMetaData.push({
                metaData: matchedItem,
                item: item,
                rowNumber: item.rowIndex,
              });
            }
  
            if (!matchedItem.workstream && item.workstream) {
              invalidWorkStreams.push({
                workstream: item.workstream,
                rowNumber: item.rowIndex,
              });
            }
            item.risk_level=determineRiskLevel(item.likelihood, item.impact)
            item.verticalId = matchedItem.vertical_id;
            item.accountId = matchedItem.account_id;
            item.workstream_id = matchedItem.workstream_id;
            item.salesForceId = matchedItem.salesforce_opportunity_id;
            item.created_by = userDetails.employee_id;
            item.createdAt = new Date(); 
            item.etaDate=convertDateFormat(etaDate)
            return item;
          }
        })
      );

      setReqDta(processedData);
  
      if (
        invalidWorkStreams.length > 0 ||
        invalidMetaData.length > 0 ||
        invalidEmails.length > 0 ||
        inValidImpact.length > 0 ||
        inValidLikelihood.length > 0 ||
        invalidRiskResponses.length > 0 ||  
        invalidStatuses.length > 0  ||
        invalidEta.length>0 || 
        inValidMatchedOwner.length>0
   
      ) {
        setOpenAlert(true);
        let errorMessage = [];
  
  
        if (invalidMetaData.length > 0) {
          errorMessage.push(
            <div style={{ fontSize: '11px', paddingBottom:"2px" }} key="invalidMetaData">
                 <div style={{fontWeight:'bold', marginLeft: '8px', fontSize: '11px'}}>Invalid Project Details:             
                 </div>
              {setInvalidMessageContent(invalidMetaData, 'metaData')}
            </div>
          );
        }

        if (invalidWorkStreams.length > 0) {
          errorMessage.push(
            <div key="invalidWorkStreams">
              <strong  style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'4px' }} >{`No workstream found:`}</strong>
              {setInvalidMessageContent(invalidWorkStreams, 'invalidWorkStreams')}
            </div>
          );
        }
  
        if (invalidEmails.length > 0) {
          errorMessage.push(
            <div style={{ fontSize: '11px' }} key="invalidEmails">
              <div style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'3px' }}>
                <strong>{`Invalid Risk owner email:`}</strong> {setInvalidMessageContent(invalidEmails, 'riskOwnerEmail')}
              </div>
            </div>
          );
        }

        if (inValidMatchedOwner.length > 0) {
          errorMessage.push(
            <div style={{ fontSize: '11px' }} key="invalidEmails">
              <div style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'3px' }}>
                <strong>{`Risk owner email not matched:`}</strong> {setInvalidMessageContent(inValidMatchedOwner, 'inValidOwner')}
              </div>
            </div>
          );
        }
  
        if (inValidImpact.length > 0) {
          errorMessage.push(
            <div key="inValidImpact" style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'3px' }}>
              <strong>{`Invalid Impact:`}</strong> {setInvalidMessageContent(inValidImpact, 'inValidImpact')}
            </div>
          );
        }
  
        if (inValidLikelihood.length > 0) {
          errorMessage.push(
            <div key="inValidLikelihood" style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'3px' }}>
              <strong>{`Invalid Likelihood:`}</strong> {setInvalidMessageContent(inValidLikelihood, 'inValidLikelihood')}
            </div>
          );
        }
  
     // risk response validation error messages
        if (invalidRiskResponses.length > 0) {
          errorMessage.push(
            <div key="invalidRiskResponses" style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'3px' }}>
              <strong>{`Invalid Risk Response:`}</strong> {setInvalidMessageContent(invalidRiskResponses, 'invalidRiskResponses')}
            </div>
          );
        }
  
     // status validation error messages
        if (invalidStatuses.length > 0) {
          errorMessage.push(
            <div key="invalidStatuses" style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'3px' }}>
              <strong>{`Invalid Status:`}</strong> {setInvalidMessageContent(invalidStatuses, 'invalidStatuses')}
            </div>
          );
        }

      // Eta validation error messages
         if (invalidEta.length > 0) {
          errorMessage.push(
            <div key="invalidStatuses" style={{ fontSize: '11px', marginLeft: '8px', paddingTop:'3px' }}>
              <strong>{`Invalid Eta:`}</strong> {setInvalidMessageContent(invalidEta, 'invalidEtaDate')}
            </div>
          );
        }
  
        setMessage(renderErrorMessage(errorMessage));
        setisDisabled(true);
      } else {
        setIsValidData(true);
        setisDisabled(false);
      }
    } catch (error) {
      console.error('Error in processing data', error);
      setisDisabled(true);
    }
  };
  
  
  
  const validateFileData = async (results, e) => {
    setisDisabled(true)
    setIsValidData(false)

    // Check if the file is empty
    if (results.data?.length === 0) {
        setOpenAlert(true)
        setMessage('Uploaded file is empty. Please fill the mandatory fields.')
        e.target.value = ''
        return
    } 
    
    // Check for file format errors
    if (results.errors?.length > 0) {
        setOpenAlert(true)
        setMessage('Invalid file. Please check the file format.')
        e.target.value = ''
        return
    }

    // Check for missing headers
    const headerValues = await new Promise((resolve) => {
        getColumnValues(e, (headerValues) => resolve(headerValues))
    })

    const deletedHeaders = columnNames.filter((column) => !headerValues.includes(column))
    setDeletedHeaderValues(deletedHeaders)

    if (deletedHeaders.length > 0) {
        setOpenAlert(true)
        const text = `Column(s) from the template are missing:`
        const missingFieldsContent = setDeletedColumnContent(deletedHeaders, text)
        setMessage(missingFieldsContent)
        e.target.value = ''
        return
    }

    // Map and prepare data from the file
    const resultedData = results.data.map((item) => ({
        rowIndex: item['#'],
        vertical: item['Vertical*'],
        account: item['Account*'],
        project: item['Project*'],
        workstream: item['Workstream'],
        category: item['Category*'],
        risk_Description: item['Risk Description*'],
        consequence: item['Consequence*'],
        existing_Controls_Or_Measures: item['Existing Controls/Measures'],
        likelihood: item['Likelihood*'],
        impact: item['Impact*'],
        risk_response: item['Risk Response'],
        risk_Response_Details: item['Risk Response Details'],
        risk_Owner: item['Risk Owner*'],
        status: item['Status*'],
        eta: item['ETA*'],
        uniqueId: item['Unique_Id*'],
    }));

    // Filter out rows where mandatory fields are empty
    const filledData = resultedData
        .map((item) => {
            const newItem = {}
            Object.keys(item).forEach((key) => {
                newItem[key.replace('*', '')] = item[key]
            })
            return newItem
        })
        .filter(({ rowIndex, vertical, account, project, workstream, risk_response, risk_Response_Details, existing_Controls_Or_Measures, status, uniqueId, ...rest }) => {
            const restValues = Object.values(rest)
            return restValues.some((value) => value !== null && value !== '' && value !== undefined)
        })

        const filledDataWithStaus = resultedData
        .map((item) => {
            const newItem = {}
            Object.keys(item).forEach((key) => {
                newItem[key.replace('*', '')] = item[key]
            })
            return newItem
        })
        .filter(({ rowIndex, vertical, account, project, workstream, risk_response, risk_Response_Details, existing_Controls_Or_Measures, uniqueId, ...rest }) => {
            const restValues = Object.values(rest)
            return restValues.some((value) => value !== null && value !== '' && value !== undefined)
        })
        console.log('filledDataWithStaus',filledDataWithStaus)

    // Handle unfilled data
    const unfilledData = resultedData.map((item) => {
        const newItem = {}
        Object.keys(item).forEach((key) => {
            newItem[key.replace('*', '')] = item[key]
        })
        return newItem
    })

    const modifiedData = unfilledData.map(({ rowIndex, vertical, account, project, workstream, risk_response, risk_Response_Details, existing_Controls_Or_Measures, status, uniqueId, ...rest }) => rest)

    const filteredData = modifiedData.filter((obj) => {
        return Object.values(obj).every((value) => value === null || value === '' || value === undefined)
    })

    // Validate filled data rows
    if (filledData.length > 0) {
      let isValid = true
      const rowsWithMissingFields = []
      const mandatoryColumnsData = [
        'category',
        'risk_Description',
        'consequence',
        'likelihood',
        'impact',
        'risk_Owner',
        'status',
        'eta',
        'uniqueId',
      ]
      for (let i = 0; i < filledData.length; i++) {
        const row = filledData[i]
        let fields = Object.keys(row)

        fields = fields.filter((field) => mandatoryColumnsData.includes(field))

        const missingFields = fields.filter((field) => !row[field])

        if (missingFields.length === 0 && !row.status) {
          missingFields.push('status')
        }
        if (missingFields?.length > 0) {
          isValid = false
          rowsWithMissingFields.push({
            rowNumber: row.rowIndex,
            missingFields: missingFields,
          })
        }
      }

      if (isValid) {
        setFileName(filledData, e)
      } else {
        //missing fields alert
        let alertMessage = ''
        if (rowsWithMissingFields.length > 0) {
          const text = 'Following rows have missing fields:'
          const missingFieldsContent = setMessageContent(
            rowsWithMissingFields,
            [],
            text,
            {}
          )
          alertMessage = missingFieldsContent
        } else {
          alertMessage = 'No data provided or all rows are missing fields.'
        }
        setOpenAlert(true)
        setMessage(alertMessage)
        e.target.value = ''
      }
    } else if (filteredData.length > 0) {
      setOpenAlert(true)
      const headerSent = 'Please fill the mandatory fields:'
      const mandatoryFieldsContent = setDeletedColumnContent(
        mandatoryColumns,
        headerSent
      )
      setMessage(mandatoryFieldsContent)
      e.target.value = ''
    }
}



  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const isExcelFile =
        file.name.endsWith('.xlsx')
        setReqDta([])
      if (isExcelFile) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          const sheetName = workbook.SheetNames[0]
          const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
          const csvData = Papa.unparse(sheetData)
          Papa.parse(csvData, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              validateFileData(results, e)
            },
          })
        }

        reader.readAsArrayBuffer(file)
      } else if (file.name.endsWith('.csv')) {
        const reader = new FileReader()
        reader.onload = async (event) => {
          const csvData = event.target.result
          Papa.parse(csvData, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              validateFileData(results, e)
            },
          })
        }
        reader.readAsText(file)
      } else {
        setOpenAlert(true)
        setMessage('Invalid file.Please check the file format.')
        e.target.value = ''
      }
    }
  }

  return (
    <>
      <Dialog
        open={props.openBulkUploadModel}
        onClose={closeBulkUploadModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        className="filesmodal"
        sx={{ height: '600px' }}
      >
        <DialogTitle style={{ fontSize: '1rem' }} id="alert-dialog-title">
        Bulk Upload - Risks
        </DialogTitle>
        <DialogContent style={{ marginBottom: '5px' }}>
          <div
            style={{
              fontSize: '12px',
              marginBottom: '14px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
             Click here to download the template.
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ExcelGenerator
              data={uploadTemplateData}
              uniqueEmployees={uniqueEmployees}
            />
          </div>
          <div
            style={{
              fontSize: '12px',
              marginTop: '14px',
              marginBottom: '14px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
           Click here to upload the data.
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                border: '1px solid black',
                padding: '5px',
                margin: '2px',
                maxWidth: 'max-content',
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                accept=".csv,.xlsx,.xls,.xlsm"
                style={{ border: 'none', flex: 1 }}
                onClick={handleInputClick}
                onChange={(e) => {
                  handleFileChange(e)
                }}
              />
            </div>
          </div>
          <div
            style={{
              fontSize: '10px',
              margin:"2px",
              display: 'flex',
              justifyContent: 'center',
              marginLeft:'-130px'
            }}
          >*Required format: .xlsx/csv </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="end">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                className="primarybtn"
                onClick={handleFileUpload}
                disabled={isDisabled}
              >
                Upload
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={closeBulkUploadModel}
                color="primary"
                autoFocus
                className="primarybtntext"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        {props.openBulkUploadModel && openAlert && (
          <Dialog
            open={props.openBulkUploadModel && openAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xs"
            className="filesmodal"
          >
            {' '}
            <IconButton
              aria-label="close"
              onClick={closeAlert}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon style={{ fontSize: '14' }} />
            </IconButton>
            <DialogContent style={{ fontSize: '12px' }}>
              <div
                style={{
                  padding: '5px',
                  margin: '2px',
                  maxWidth: 'max-content',
                  wordWrap: 'break-word',
                }}
              >
                {message}
              </div>
            </DialogContent>
          </Dialog>
        )}
        <Loading open={openBackdrop} />
      </Dialog>
    </>
  )
}

export default RisksBulkUpload
