import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../Common/Loading'

import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp'
import {
  getCsatData,
  getCsatHistoricalData,
} from '../../../services/csatService'
import CsatView from './csat'
import Marquee from './Marquee'

import {
  updateCsatFilterData,
  updateCsatHistoricalFilterData,
  updateCsatHistoricalInitialData,
  updateCsatRevieweeInitialData,
} from '../../../redux/reducer/projectHealthFilterSlice'
import './index.css'

const Csat = () => {
  const [expanded, setExpanded] = useState(false)
  const [currentData, setCurrentData] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const { data: adminData } = useSelector((state) => state.admin)
  const { userDetails } = useSelector((state) => state.user)
  const [uniqueAccount, setUniqueAccount] = useState([])
  const [uniqueProjects, setUniqueProjects] = useState([])
  const dispatch = useDispatch()
  const [excludedRows, setExcludedRows] = useState([])
  const [nonExcludedRows, setNonExcludedRows] = useState([])
  const [uniqueAccountCount, setUniqueAccountCount] = useState([])
  const [excludeFilter, setExcludeFilter] = useState(false)
  const [totalNominatedRows, setTotalNominatedRows] = useState([])
  const [currentaccordianexpanded, setCurrentAccordianExpanded] =
    useState(false)
  const [historicalaccordianexpanded, setHistoricalAccordianExpanded] =
    useState(false)
  const isBetweenSmAndMd = window.matchMedia('(max-width: 910px)').matches
  const [filteredAccountNomination, setFilteredAccountNomination] = useState([])
  const [totalFilteredNomination, setTotalFilteredNomination] = useState([])
  const { csatFilterData } = useSelector((state) => state.filter)

  useEffect(() => {
    const nominations = JSON.parse(JSON.stringify(nonExcludedRows))
    const excludedNominations = JSON.parse(JSON.stringify(excludedRows))
    if (csatFilterData?.project?.length > 0) {
      let projectfilterData = []
      if (!excludeFilter) {
        projectfilterData = nominations.filter(
          (data) =>
            csatFilterData.project?.includes(data?.project) &&
            csatFilterData.account?.includes(data?.account) &&
            csatFilterData.vertical?.includes(data?.vertical)
        )
      } else {
        projectfilterData = excludedNominations.filter(
          (data) =>
            csatFilterData.project?.includes(data?.project) &&
            csatFilterData.account?.includes(data?.account) &&
            csatFilterData.vertical?.includes(data?.vertical)
        )
      }
      setTotalFilteredNomination(projectfilterData)
      const accountdata = nominations
        .filter(
          (data) =>
            uniqueAccountCount?.includes(data?.account) &&
            csatFilterData.project?.includes(data?.project) &&
            csatFilterData.vertical?.includes(data?.vertical)
        )
        .map((a) => a.account)
      setFilteredAccountNomination(_.uniq(accountdata))
    } else if (csatFilterData?.account?.length > 0) {
      let accountfilterData = []
      if (!excludeFilter) {
        accountfilterData = nominations.filter(
          (data) =>
            csatFilterData.account?.includes(data?.account) &&
            csatFilterData.vertical?.includes(data?.vertical)
        )
      } else {
        accountfilterData = excludedNominations.filter(
          (data) =>
            csatFilterData.account?.includes(data?.account) &&
            csatFilterData.vertical?.includes(data?.vertical)
        )
      }
      setTotalFilteredNomination(accountfilterData)
      const accountdata = uniqueAccountCount.filter((data) =>
        csatFilterData.account?.includes(data)
      )
      setFilteredAccountNomination(accountdata)
    } else if (csatFilterData?.vertical.length > 0) {
      let verticaldata = []
      if (!excludeFilter) {
        verticaldata = nominations.filter((data) =>
          csatFilterData.vertical?.includes(data?.vertical)
        )
      } else {
        verticaldata = excludedNominations.filter((data) =>
          csatFilterData.vertical?.includes(data?.vertical)
        )
      }
      setTotalFilteredNomination(verticaldata)
      const accountdata = nominations
        .filter(
          (data) =>
            uniqueAccountCount?.includes(data?.account) &&
            csatFilterData.vertical?.includes(data?.vertical)
        )
        .map((a) => a.account)

      setFilteredAccountNomination(_.uniq(accountdata))
    } else {
      if (!excludeFilter) {
        setTotalFilteredNomination(nonExcludedRows)
      } else {
        setTotalFilteredNomination(excludedNominations)
      }

      setFilteredAccountNomination(uniqueAccountCount)
    }
  }, [csatFilterData, excludeFilter])

  useEffect(() => {
    dispatch(
      updateCsatFilterData({
        vertical: [],
        account: [],
        project: [],
        // exclude: [],
        // health_status: [],
      })
    )
    dispatch(
      updateCsatHistoricalFilterData({
        vertical: [],
        account: [],
        project: [],
        time_period: [],
        // health_status: [],
      })
    )
  }, [])

  const getCsat = () => {
    setOpenBackdrop(true)
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    getCsatData(data)
      .then((res) => {
        if (res.success) {
          setCurrentData(res.data.result)
          if (res.data.result.length > 0) {
            const nonExcludedData = res.data.result.filter(
              (row) =>
                row?.exclude !== 'yes' &&
                row.timePeriod !== null &&
                row.survey_type !== null &&
                row.timePeriod !== '' &&
                row.survey_type !== ''
            )
            const totalNominations = res.data.result.filter(
              (row) =>
                // row?.exclude !== 'yes' &&
                row.time_period !== null && row.time_period !== ''
              // row.survey_type !== ''
            )

            setTotalNominatedRows(totalNominations)
            const excludedData = res.data.result.filter(
              (row) => row?.exclude === 'yes'
            )
            setExcludedRows(excludedData)
            if (!excludeFilter) {
              setTotalFilteredNomination(nonExcludedData) //to get total nomonations
            } else {
              setTotalFilteredNomination(excludedData) //to get total nomonations
            }
            setNonExcludedRows(nonExcludedData)
            if (nonExcludedData.length > 0) {
              const uniqueAccounts = _.uniq(_.map(nonExcludedData, 'account'))
              setUniqueAccountCount(uniqueAccounts)
              setFilteredAccountNomination(uniqueAccounts)
            } else {
              setUniqueAccountCount([])
            }
          }
          dispatch(updateCsatRevieweeInitialData(res.data.result))
          // dispatch(updateCsatHistoricalInitialData(res.data.result))
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }

  useEffect(() => {
    getCsat()
  }, [])

  useEffect(() => {
    // Update uniqueAccount and uniqueProjects when rows are updated
    const newUniqueAccount = _.uniq(_.map(currentData, 'account'))?.sort()
    const newUniqueProjects = _.uniq(_.map(currentData, 'project'))?.sort()

    // Check if the state needs to be updated
    if (!_.isEqual(uniqueAccount, newUniqueAccount)) {
      setUniqueAccount(newUniqueAccount)
    }

    if (!_.isEqual(uniqueProjects, newUniqueProjects)) {
      setUniqueProjects(newUniqueProjects)
    }
  }, [currentData, uniqueAccount, uniqueProjects])

  const getCsatHistory = () => {
    setOpenBackdrop(true)
    const data = {
      accounts: uniqueAccount,
      timePeriod: '2024 H2',
      // role: userDetails.role,
    }
    getCsatHistoricalData(data)
      .then((res) => {
        if (res.success) {
          setHistoryData(res.data.result)
          dispatch(updateCsatHistoricalInitialData(res.data.result))
        }
        setOpenBackdrop(false)
      })
      .catch((error) => {
        console.error('Error while fetching history csat:', error)
        setOpenBackdrop(false)
      })
    // setOpenBackdrop(false)
  }

  const getCsatAdminHistory = () => {
    setOpenBackdrop(true)
    const data = {
      accounts: uniqueAccount,
      timePeriod: '2024 H2',
      role: userDetails.role,
    }
    getCsatHistoricalData(data)
      .then((res) => {
        if (res.success) {
          setHistoryData(res.data.result)
          dispatch(updateCsatHistoricalInitialData(res.data.result))
        }
        setOpenBackdrop(false)
      })
      .catch((error) => {
        console.error('Error while fetching history csat:', error)
        setOpenBackdrop(false)
      })
    // setOpenBackdrop(false)
  }
  useEffect(() => {
    if (userDetails.role === 'Admin') {
      getCsatAdminHistory()
    }
  }, [userDetails.role])

  useEffect(() => {
    if (
      uniqueAccount?.length &&
      (userDetails.role === 'Reviewer' || userDetails.role === 'Reviewee')
    ) {
      getCsatHistory()
    }
  }, [uniqueAccount, userDetails.role])

  const handleCurrentAccordionChange = () => {
    setCurrentAccordianExpanded(!currentaccordianexpanded)
  }
  const handleHistoricalAccordionChange = () => {
    setHistoricalAccordianExpanded(!historicalaccordianexpanded)
  }

  let backendURL = `http://localhost:1337`

  if (process.env.NODE_ENV === 'production') {
    backendURL = `https://project-review.tredence.com`
  }

  return (
    <>
      <Loading open={openBackdrop} />
      <div>
        {/* <Marquee text="CSAT nomination window is closed for 2024 H1. Please reach out to DX team for assistance." /> */}
        <Marquee text="CSAT nomination window for 2024 H2 is open. Please reach out to DX for any assistance." />
      </div>
      <div className="csatcontainer">
        <Paper
          elevation={4}
          style={{ padding: '1.2rem', marginTop: '3.15rem' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1.2rem',
            }}
          >
            <div style={{ fontSize: '20px', color: '#F58220' }}>
              Stakeholder Nominations
            </div>
            <span style={{ fontSize: '12px' }}>
              <a
                href={`${backendURL}/csat/downloadUserManual`}
                download={'User Manual'}
              >
                User Manual
              </a>
            </span>
          </div>
          <TableContainer
            className="tablecontainer"
            component={Paper}
            elevation={0}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="tablecell"
                    style={
                      !currentaccordianexpanded && isBetweenSmAndMd
                        ? { maxWidth: '90vw' }
                        : {}
                    }
                  >
                    <Accordion>
                      <AccordionSummary
                        onClick={handleCurrentAccordionChange}
                        expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                      >
                        <Typography
                          style={{
                            fontSize: '14px',
                            color: 'primary',
                          }}
                        >
                          Current Nominations - 2024 H2
                          {!excludeFilter ? (
                            <>
                              <div
                                style={{
                                  fontSize: '10px',
                                  color: 'primary',
                                }}
                              >
                                {`Count of nomination(s): ${
                                  totalFilteredNomination?.length > 0
                                    ? totalFilteredNomination.length
                                    : 0
                                } `}
                              </div>
                              <div
                                style={{
                                  fontSize: '10px',
                                  color: 'primary',
                                }}
                              >
                                {`No. of account(s) nominated: ${
                                  filteredAccountNomination?.length > 0
                                    ? filteredAccountNomination?.length
                                    : 0
                                }`}
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                fontSize: '10px',
                                color: 'primary',
                              }}
                            >
                              {`Count of excluded nomination(s): ${
                                totalFilteredNomination?.length > 0
                                  ? totalFilteredNomination?.length
                                  : 0
                              } `}
                            </div>
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CsatView
                          accounts={uniqueAccount}
                          projectList={uniqueProjects}
                          rows={currentData}
                          getCsat={getCsat}
                          getCsatHistoricalData={getCsatHistory}
                          view={'current'}
                          setExcludeFilter={setExcludeFilter}
                          nominatedRecord={totalNominatedRows}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="tablecell"
                    style={
                      !historicalaccordianexpanded && isBetweenSmAndMd
                        ? { maxWidth: '90vw' }
                        : {}
                    }
                  >
                    <Accordion>
                      <AccordionSummary
                        onClick={handleHistoricalAccordionChange}
                        expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                      >
                        <Typography
                          style={{
                            fontSize: '14px',
                            color: 'primary',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Historical Nominations
                          {/* {userDetails.role == 'Admin' ? ( */}
                          <Tooltip title="Nominate historical clients for 2024 H2 (* Modifications Required)">
                            <IconButton className="blinking-icon">
                              <ErrorOutlineSharpIcon
                                sx={{ color: '#F58220' }}
                              />
                            </IconButton>
                          </Tooltip>
                          {/* ) : (
                            ''
                          )} */}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CsatView
                          accounts={uniqueAccount}
                          projectList={uniqueProjects}
                          rows={historyData}
                          getCsat={getCsat}
                          getCsatHistoricalData={getCsatHistory}
                          view={'historical'}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  )
}

export default Csat
