import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getRiskByUniqueId,
  getRiskdata,
  getRiskOptions,
  getAccountGenericRiskdata,
} from '../../services/riskManagementService'

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  isLoadingRisk: false,
  errorFetchRisk: null,
  riskData: [],
  isLoadingRiskForPrj: false,
  errorFetchRiskForPrj: null,
  riskDataForPrj: [],
  selectedRisksData: [],
  isLoadingaccountgeneicRisk: false,
  errorFetchaccountgeneicRisk: null,
  accountgenericriskData: [],
}

export const fetchRiskOptions = createAsyncThunk(
  'content/fetchRiskOptions',
  async () => {
    const response = await getRiskOptions()
    if (response.success) {
      return response.data.result
    }

    return {}
  }
)

export const getRiskrecods = createAsyncThunk(
  'content/getRiskrecods',
  async (data) => {
    const response = await getRiskdata(data)
    if (response.success) {
      return response.data
    }
    return []
  }
)

export const getRiskByProject = createAsyncThunk(
  'content/getRiskByProject',
  async (data, { rejectWithValue }) => {
    const response = await getRiskByUniqueId(data)
    if (response?.success) {
      return response?.data
    } else {
      return rejectWithValue(
        'Server Error: Something went wrong on the server.'
      )
    }
  }
)

export const getAccountGenericRiskrecods = createAsyncThunk(
  'content/getAccountGenericRiskrecods',
  async (data) => {
    const response = await getAccountGenericRiskdata(data)
    if (response.success) {
      return response.data
    }
    return []
  }
)

export const riskSlice = createSlice({
  name: 'risk',
  initialState,
  reducers: {
    updateSelectedRisksData: (state, action) => {
      state.selectedRisksData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRiskOptions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchRiskOptions.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchRiskOptions.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
    builder.addCase(getRiskrecods.pending, (state) => {
      state.isLoadingRisk = true
    })
    builder.addCase(getRiskrecods.fulfilled, (state, action) => {
      state.isLoadingRisk = false
      state.riskData = action.payload
    })
    builder.addCase(getRiskrecods.rejected, (state, action) => {
      state.isLoadingRisk = false
      state.errorFetchRisk = action.error.message
    })
    builder
      .addCase(getRiskByProject.pending, (state) => {
        state.isLoadingRiskForPrj = true
        state.errorFetchRiskForPrj = false
      })
      .addCase(getRiskByProject.fulfilled, (state, action) => {
        state.isLoadingRiskForPrj = false
        state.riskDataForPrj = action.payload
      })
      .addCase(getRiskByProject.rejected, (state, action) => {
        state.isLoadingRiskForPrj = false
        state.errorFetchRiskForPrj = true
      })
    builder.addCase(getAccountGenericRiskrecods.pending, (state) => {
      state.isLoadingaccountgeneicRisk = true
    })
    builder.addCase(getAccountGenericRiskrecods.fulfilled, (state, action) => {
      state.isLoadingaccountgeneicRisk = false
      state.accountgenericriskData = action.payload
    })
    builder.addCase(getAccountGenericRiskrecods.rejected, (state, action) => {
      state.isLoadingaccountgeneicRisk = false
      state.errorFetchaccountgeneicRisk = action.error.message
    })
  },
})
export const { updateSelectedRisksData } = riskSlice.actions

export default riskSlice.reducer
